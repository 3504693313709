/* eslint-disable eqeqeq */
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Inject, OnDestroy, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormioComponent } from '@formio/angular';
import { AnalyticService } from 'app/core/analytic/analytic.service';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';


@Component({
    selector: 'new-dashboard',
    templateUrl: 'new-dashboard.dialog.html',
})
export class NewDashboardDialog implements OnDestroy {

    @ViewChild(FormioComponent, { static: false }) form: FormioComponent;

    public dashboard_form: any = {}
    public dashboard_data: any

    public dashboards_list: any = []
    public selected_dashboard: any
    data_refresh: EventEmitter<any> = new EventEmitter()

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    datasource: any
    datetime_dimensions: any
    selector_dimensions: any

    constructor(
        public dialogRef: MatDialogRef<NewDashboardDialog>,
        @Inject(MAT_DIALOG_DATA) public _data: any,
        private _httpClient: HttpClient,
        private _analyticsService: AnalyticService) {

        this._httpClient.get<any>(`/assets/forms/dashboard.json`)
            .pipe(takeUntil(this._unsubscribeAll), take(1))
            .subscribe(
                async (dashboard_form) => {

                    let copyWidgetsFrom = {
                        "label": "Copy widgets from",
                        "widget": "choicesjs",
                        "tableView": false,
                        "data": {
                            "values": [
                                {
                                    "label": "No data, reload",
                                    "value": ""
                                }
                            ]
                        },
                        "selectThreshold": 0.3,
                        "key": "copyFrom",
                        "type": "select",
                        "indexeddb": {
                            "filter": {}
                        },
                        "searchResultLimit": 10,
                        "input": true,
                        "validate": {
                            "required": false
                        },
                        "customOptions": {
                            "searchResultLimit": 10
                          },
                        dataSrc: "values",
                    }

                    dashboard_form.components.splice(dashboard_form.components.length - 1, 0, copyWidgetsFrom)

                    // dashboard_form.components.push(copyWidgetsFrom)

                    this.dashboard_form = dashboard_form

                    this._analyticsService.getAllDashboards().subscribe(
                        (res) => {
                            // this.dashboards_list = res
                            let dashList = []
                            res.forEach((element: any) => {
                                const dash = {
                                    id: element.payload.doc.id,
                                    ...element.payload.doc.data(),
                                };
                                dashList.push({ "label": `${dash.owner} | ${dash.title ? dash.title['en'] : dash.tab_info ? dash.tab_info.title : 'No Name'}`, "value": dash.widgets ?  dash.widgets : dash.dashboard ? dash.dashboard : []})
                            });

                            this.dashboard_form.components.find(el => el.key == 'copyFrom').data.values = dashList
                            this.data_refresh.emit({
                                property: 'form',
                                value: this.dashboard_form
                            })
                        }
                    )

                    if (this._analyticsService.sisense_app === undefined) {
                        await this._analyticsService.initSisense();
                    }

                    let datasources = []
                    this._analyticsService.getDatasourcesList().then(
                        (res: any) => {
                            // console.log('res',res)
                            res.forEach(element => {
                                datasources.push({ "label": element.title, "value": element.title })
                            });

                            this.dashboard_form.components.find(el => el.key == 'datasource').data.values = datasources
                            this.data_refresh.emit({
                                property: 'form',
                                value: this.dashboard_form
                            })
                        }
                    )
                }
            )
    }

    onFormChange($event){
        if($event.changed?.component?.key == 'datasource' && $event.changed.value != this.datasource){
            this.datasource = $event.changed.value
            this._analyticsService.getDimensions(this.datasource).then(
                (res:any ) => {
                    this.datetime_dimensions = res.filter(el => el.dimtype == 'datetime')
                    this.selector_dimensions = res.filter(el => el.dimtype == 'text')

                    let  datetime_selector = []
                    this.datetime_dimensions.forEach(element => {
                        datetime_selector.push({"value": element.id, "label": element.id})
                    });

                    let  selector = []
                    this.selector_dimensions.forEach(element => {
                        selector.push({"value": element.id, "label": element.id})
                    });


                    this.dashboard_form.components.find(el => el.key == 'dateTimeFilter').components.find(el => el.key == 'timeDimension').data.values = datetime_selector

                    this.dashboard_form.components.find(el => el.key == 'selectorFilter').components.find(el => el.key == 'selector').data.values = selector
                    this.dashboard_form.components.find(el => el.key == 'selectorFilter').components.find(el => el.key == 'grouping').data.values = selector

                    this.data_refresh.emit({
                        property: 'form',
                        value: this.dashboard_form
                    })

                }
            )
        }
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    newDashboard($event) {
        this.dialogRef.close($event)
    }

    copyDashboard($event) {
        this.dialogRef.close($event)

    }

    submitForm() {
        this.form.submit.next()
    }

    /**
 * On destroy
 */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

}
