/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable, OnDestroy } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { FuseNavigationService } from '@fuse/components/navigation';
import { TranslocoService } from '@ngneat/transloco';
import { environment } from 'environments/environment';
import { isEqual } from 'lodash';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { distinctUntilChanged, switchMap, take, takeUntil } from 'rxjs/operators';

@Injectable()
export class ModulesService implements OnDestroy{
    public _fOrgID$: ReplaySubject<any> = new ReplaySubject<any>(1);
    public modules: Observable<any>;

    orgId: string = ''
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    public isAdmin = false;
    /**
     * Constructor
     */
    constructor(
        private afs: AngularFirestore,
        private _fuseNavigationService: FuseNavigationService,
        private _translocoService: TranslocoService,
    ) {
        /**
         * Subscribe to user id value change and return an Observable with the current user value
         */
        this.modules = this._fOrgID$.pipe(
            switchMap((uid) => {
                if (uid !== '') {
                    this.orgId = uid
                    return afs.collection('orgs').doc(uid).collection('modules').snapshotChanges()
                        .pipe(takeUntil(this._unsubscribeAll))
                        .pipe(distinctUntilChanged((prev: any, curr) => isEqual(prev?.payload?.data(), curr?.payload?.data())));
                } else {
                    return Promise.reject('Org ID not set');
                }
            })
        );

        this.createModulesNavigationMenu()
    }

    createModulesNavigationMenu(){
        this.modules
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(
            (modules: any) => {
                if (modules === undefined) {
                    return;
                }

                // if(this.isAdmin){
                    this._translocoService.selectTranslate('NAVIGATION.Controllers').pipe(take(1))
                    .subscribe((translation) => {

                        const menuDashboard = [];
                        modules.forEach((tempModule) => {
                            const module = {
                                id: tempModule.payload.doc.id,
                                ...tempModule.payload.doc.data(),
                            };


                            if (module.id.includes('smartpricer') && module.enabled) {
                                menuDashboard.push({
                                    id: module.id,
                                    title: 'Smart Pricer',
                                    type: 'basic',
                                    icon: 'settings',
                                    link: '/controllers/smartpricer'
                                });
                            } else if (module.id.includes('communitymanager') && module.enabled) {
                                menuDashboard.push({
                                    id: module.id,
                                    title: 'Community Manager',
                                    type: 'basic',
                                    icon: 'location_city',
                                    link: '/controllers/community-manager'
                                });
                            }

                        });

                        if(menuDashboard.length > 0){
                            const controllersGroup: any = {
                                id: 'controllers',
                                title: translation,
                                translate: 'NAV.MODULES',
                                type: 'group',
                                icon: 'build',
                                children: menuDashboard,
                            };

                            const tempNavigation: any =
                                this._fuseNavigationService.getComponent('mainNavigation');

                            const controllersIndex = tempNavigation.navigation.findIndex(
                                (x: any) => x.id === 'controllers'
                            );


                            if (controllersIndex !== -1) {
                                tempNavigation.navigation[controllersIndex] = controllersGroup;
                            } else {
                                const adminIndex = tempNavigation.navigation.findIndex(
                                    (x: any) => x.id === 'admin'
                                );

                                if(adminIndex !== -1){
                                    tempNavigation.navigation.splice(adminIndex, 0, controllersGroup)
                                } else {
                                    tempNavigation.navigation.push(controllersGroup)
                                }
                            }

                            tempNavigation.refresh();
                        } else {
                            const tempNavigation: any =
                                this._fuseNavigationService.getComponent('mainNavigation');

                            const controllersIndex = tempNavigation.navigation.findIndex(
                                (x: any) => x.id === 'controllers'
                            );
                            if (controllersIndex !== -1) {
                                tempNavigation.navigation.splice(controllersIndex, 1)
                            }
                        }

                    })
                // }
            }
        );
    }

    async getSmartPricerConfigs(orgId, pricerID): Promise<any>{
        const configs_ref = this.afs.collection('orgs').doc(orgId).collection('modules').doc(pricerID).collection('configs').ref;

        const userConfigQuery = await configs_ref.where('type', '==', 'customer').orderBy('createdDate', 'desc').limit(1).get();
        const masterConfigQuery = await configs_ref.where('type', '==', 'master').orderBy('createdDate', 'desc').limit(1).get();

        let userConfig: any = {};
        let masterConfig: any = {};

        userConfigQuery.forEach((doc) => {
            userConfig =  doc.data();
            userConfig.id = doc.id
          });

        masterConfigQuery.forEach(doc => {
            masterConfig =  doc.data();
            masterConfig.id = doc.id;
          });

        userConfig.schedule = new Date(userConfig.schedule.seconds * 1000).toUTCString()

        return [userConfig, masterConfig];
    }

    async updatetSmartPricer(orgId, smart_pricer, userId): Promise<any>{
        const orgRef: AngularFirestoreDocument<any> = this.afs.doc(`orgs/${orgId}`);
        if(smart_pricer !== undefined){
            const smartpricerRef: AngularFirestoreDocument<any> = orgRef.collection('modules').doc(`smartpricer-${environment.modules.version}`);
            await smartpricerRef.set({}, {merge: true});

            let master_config = {
                createdBy: userId,
                createdDate: new Date(),
                json: smart_pricer.masterconfig,
                type: "master"
            }

            let user_config = {
                createdBy: userId,
                createdDate: new Date(),
                json: smart_pricer.userconfig,
                schedule: new Date(smart_pricer.schedule),
                type: "customer",
                status: smart_pricer.status
            }

            await smartpricerRef.collection('configs').doc().set(master_config);
            await smartpricerRef.collection('configs').doc().set(user_config);
        }
        return true;
    }

    async getCommunityManagerDBConfig(orgId, managerID): Promise<any>{
        const configs_ref = this.afs.collection('orgs').doc(this.orgId).collection('modules').doc(managerID).collection('configs').ref;

        const userConfigQuery = await configs_ref.where('type', '==', 'customer').orderBy('createdDate', 'desc').limit(1).get();
        const masterConfigQuery = await configs_ref.where('type', '==', 'master').orderBy('createdDate', 'desc').limit(1).get();

        let userConfig: any = {};
        let masterConfig: any = {};

        userConfigQuery.forEach((doc) => {
            userConfig =  doc.data();
            userConfig.id = doc.id
          });

        masterConfigQuery.forEach(doc => {
            masterConfig =  doc.data();
            masterConfig.id = doc.id;
          });

        userConfig.schedule = new Date(userConfig.schedule.seconds * 1000).toUTCString()

        return [userConfig, masterConfig];
    }

    async updateCommunityManagerDBConfig( community_manager): Promise<any>{
        const orgRef: AngularFirestoreDocument<any> = this.afs.doc(`orgs/${this.orgId}`);
        if(community_manager !== undefined){
            const community_manager_ref: AngularFirestoreDocument<any> = orgRef.collection('modules').doc(`communitymanager-${environment.modules.version}`);
            // await smartpricerRef.set({}, {merge: true});

            // let master_config = {
            //     createdBy: userId,
            //     createdDate: new Date(),
            //     json: smart_pricer.masterconfig,
            //     type: "master"
            // }

            // let user_config = {
            //     createdBy: userId,
            //     createdDate: new Date(),
            //     json: smart_pricer.userconfig,
            //     schedule: new Date(smart_pricer.schedule),
            //     type: "customer",
            //     status: smart_pricer.status
            // }

            await community_manager_ref.set(community_manager,{merge: true});
            // await smartpricerRef.collection('configs').doc().set(user_config);
        }
        return true;
    }


    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
