<!-- Navigation -->
<fuse-vertical-navigation
    class="dark bg-indigo-800 text-white print:hidden"
    [mode]="isScreenSmall ? 'over' : 'side'"
    [name]="'mainNavigation'"
    [navigation]="navigation.default"
    [opened]="!isScreenSmall">
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationHeader>
        <!-- Logo -->
        <!-- <div class="flex items-center h-40 p-6 pb-0" *ngIf="!this.isAdmin">
            <img
                class="w-30 h-30"
                [src]="selectedOrg?.logo">
        </div>

        <div class="flex items-center h-40 p-6 pb-0" *ngIf="this.isAdmin">
            <img
                class="w-30 h-30"
                [src]="selectedOrg?.logo">
        </div> -->
        <div class="grid items-center justify-items-center h-30 p-6 mb-2" *ngIf="this.isAdmin">
            <!-- Light version -->
            <img loading=lazy *ngIf="selectedOrg?.logo"
                [className]="selectedOrg.logo_white_background ? 'dark:hidden object-contain h-28 w-auto rounded-2xl bg-card shadow bg-white p-2': 'dark:hidden object-contain h-28 w-auto'"
                [src]="selectedOrg?.logo" alt="Logo image">
            <!-- Dark version -->
            <img loading=lazy *ngIf="selectedOrg?.logo"
                [className]="selectedOrg.logo_white_background ? 'object-contain h-28 w-auto rounded-2xl bg-card shadow bg-white p-2': 'object-contain h-28 w-auto'"
                [src]="selectedOrg?.logo" alt="Logo image">

            <!-- <span class="text-4xl ml-4">{{selectedOrg?.name}}</span> -->
        </div>

        <div class="grid items-center justify-items-center h-30 p-6 mb-2" *ngIf="!this.isAdmin">
            <!-- Light version -->
            <img loading=lazy
                class="object-contain w-auto"
                src="./assets/images/logo/logo_HP.png"
                alt="Logo image">

            <!-- <span class="text-4xl ml-4">{{selectedOrg?.name}}</span> -->
        </div>
    </ng-container>
    <!-- Navigation footer hook -->
    <ng-container fuseVerticalNavigationFooter>
        <!-- User -->
        <div class="flex items-center w-full px-6 py-8 border-t">
            <user></user>
            <div class="flex flex-col w-full ml-4 overflow-hidden">
                <!-- <div class="w-full whitespace-nowrap overflow-ellipsis overflow-hidden leading-normal text-current opacity-80">
                    {{user.name}}
                </div> -->
                <div class="w-full mt-0.5 whitespace-nowrap text-sm overflow-ellipsis overflow-hidden leading-normal text-current opacity-50">
                    {{user.name}}
                </div>
            </div>
        </div>
    </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">

    <!-- Header -->
    <div class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
        <!-- Navigation toggle button -->
        <button
            class="mr-2"
            mat-icon-button
            (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
        </button>

        <date-selector class='relative mt-1 ml-auto mr-auto'></date-selector>
        <community-manager-selector class='relative mt-1 ml-auto mr-auto' *ngIf="this.isCommunityManager"></community-manager-selector>

        <!-- Components -->
        <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
            <!-- <languages></languages>
            <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
            <search [appearance]="'bar'"></search>
            <shortcuts></shortcuts>
            <messages></messages>
            <notifications></notifications>
            <button
                class="lg:hidden"
                mat-icon-button
                (click)="quickChat.toggle()">
                <mat-icon [svgIcon]="'heroicons_outline:chat-alt-2'"></mat-icon>
            </button> -->

            <org-select></org-select>
            <edit-dashboard></edit-dashboard>
                        <reload-dashboard></reload-dashboard>
            <download-dashboard></download-dashboard>
            <languages></languages>
            <fuse-fullscreen class="hidden md:block" [tooltip]="'TOOLBAR.FULLSCREEN.toggle' | transloco"></fuse-fullscreen>
<!-- 
            <search [appearance]="'bar'"></search>
            <shortcuts></shortcuts>
            <messages></messages>
            <notifications></notifications>
            <button
                class="lg:hidden"
                mat-icon-button
                (click)="quickChat.toggle()">
                <mat-icon [svgIcon]="'heroicons_outline:chat-alt-2'"></mat-icon>
            </button> -->
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>

    <!-- Footer -->
    <!-- <div class="relative flex flex-0 items-center justify-start w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">
        <span class="font-medium text-secondary">Fuse &copy; {{currentYear}}</span>
    </div> -->

</div>

<!-- Quick chat -->
<quick-chat #quickChat="quickChat" hidden></quick-chat>
