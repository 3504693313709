<div class="flex mt-4 font-sans" *ngIf="this.router.url.includes('analytic')">
    <button mat-icon-button class='mt-1' (click)="this.back()">
        <mat-icon class="s-40">keyboard_arrow_left</mat-icon>
    </button>
    <mat-form-field  appearance="outline" class='flex fuse-mat-dense flex-auto'>
        <input #calendar matInput type="text" ngxDaterangepickerMd [(ngModel)]="selected" [showCustomRangeLabel]="true"
            [alwaysShowCalendars]="true" [ranges]="ranges" [linkedCalendars]="true" [showClearButton]="false"
            placeholder="Select please..." (datesUpdated)="updatedFilter($event)" [locale]="locale"  [minDate]='minDate' [maxDate]="maxDate"/>
    </mat-form-field>
    <button mat-icon-button  class='mt-1' (click)="this.forward()" >
        <mat-icon class="s-40">keyboard_arrow_right</mat-icon>
    </button>
</div>
