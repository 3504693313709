import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { take, takeUntil } from 'rxjs/operators';
import { TranslocoService } from '@ngneat/transloco';
import { OrgSelectService } from './org-select.service';
import { OrgService } from 'app/core/org/org.service';
import { AnalyticService } from 'app/core/analytic/analytic.service';
import { ModulesService } from 'app/core/modules/modules.service';
import { UserService } from 'app/core/user/user.service';
import { MatDialog } from '@angular/material/dialog';
import { NewOrgDialog } from './new_org/new-org.dialog';
import { Subject } from 'rxjs';
import { NewDashboardDialog } from './new_dashboard/new-dashboard.dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DashboardInterface } from 'app/core/analytic/dashboard.model';
import { CompactType, DisplayGrid, GridType } from 'angular-gridster2';
import * as Sentry from "@sentry/angular";
@Component({
    selector: 'org-select',
    templateUrl: './org-select.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'orgSelect'
})
@Sentry.TraceClassDecorator()
export class OrgSelectComponent implements OnInit, OnDestroy {
    availableOrgs: any = [];
    selectedOrg: any = '';
    isAdmin: boolean = false;

    private _unsubscribeAll: Subject<any> = new Subject<any>();
    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _userService: UserService,
        private _orgSelectService: OrgSelectService,
        private orgService: OrgService,
        private analyticService: AnalyticService,
        private modulesService: ModulesService,
        public dialog: MatDialog,
        private _matSnackBar: MatSnackBar,
        private translocoService: TranslocoService,
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this._userService.profile
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                (res) => {
                    if (res.claim.hasOwnProperty('admin')) {
                        this.isAdmin = true;
                        this._orgSelectService.snapshotOrgs()
                            .pipe(takeUntil(this._unsubscribeAll))
                            .subscribe(
                                (orgs) => {
                                    this.availableOrgs = [];
                                    orgs.forEach((tempOrg) => {
                                        const org = {
                                            id: tempOrg.payload.doc.id,
                                            ...tempOrg.payload.doc.data(),
                                        };

                                        this.availableOrgs.push(org);
                                    });

                                    this.orgService.org
                                        .pipe(takeUntil(this._unsubscribeAll))
                                        .subscribe(
                                            (tempOrg) => {
                                                this.selectedOrg = tempOrg.id;
                                                this._changeDetectorRef.detectChanges();
                                            }
                                        )

                                }
                            );
                    }
                }
            );


    }

    changeOrg($event): void {
        this._userService.addAdministrationMenu(this.selectedOrg);
        this.orgService._fOrgID$.next(this.selectedOrg);
        this.analyticService._fOwnerID$.next(this.selectedOrg);
        this.modulesService._fOrgID$.next(this.selectedOrg);
    }

    newOrg() {
        const logoDialog = this.dialog.open(NewOrgDialog, {
            width: '400px',
            data: { 'orgs': this.availableOrgs }
        });

        logoDialog.afterClosed()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                (org) => {
                    if (org) {
                        org['logo_white_background'] = false;
                        this.orgService.updateOrg(org).then(
                            (res) => {

                                this.translocoService.selectTranslate("FORMS.CRUD.was_created")
                                    .pipe(takeUntil(this._unsubscribeAll), take(1))
                                    .subscribe((translation) => {
                                        this._matSnackBar.open(`${org.name} ${translation}`, 'Ok!', {
                                            verticalPosition: 'top',
                                            duration: 2000
                                        });
                                    })
                            }
                        )
                    }
                }
            );
    }

    newDashboard() {
        const dashboardDialog = this.dialog.open(NewDashboardDialog, {
            width: '800px',
            data: {}
        });

        dashboardDialog.afterClosed()
            .pipe(takeUntil(this._unsubscribeAll), take(1))
            .subscribe(
                (dashboard_info) => {
                    if(dashboard_info){
                        this.orgService.org
                        .pipe(takeUntil(this._unsubscribeAll), take(1))
                        .subscribe(
                            (org) => {
                                let dashboard: DashboardInterface = {}

                                dashboard.index = Number.MAX_SAFE_INTEGER;

                                dashboard.datasource = dashboard_info.data.datasource;

                                dashboard.icon = dashboard_info.data.icon
                                dashboard.title = { en: dashboard_info.data.en, de: dashboard_info.data.de, it: dashboard_info.data.it, fr: dashboard_info.data.fr }
                                dashboard.timeFilter = { timeFilterEnabled: dashboard_info.data.timeFilterEnabled, defaultDate: dashboard_info.data.defaultDate, maxDate: dashboard_info.data.maxDate, minDate: dashboard_info.data.minDate, timeDimension: dashboard_info.data.timeDimension }
                                dashboard.selectorFilter = { selectorFilterEnabled: dashboard_info.data.selectorFilterEnabled, selector: dashboard_info.data.selector, grouping: dashboard_info.data.grouping }
                                dashboard.gridOptions = {
                                    gridType: GridType.ScrollVertical,
                                    compactType: CompactType.None,
                                    margin: 10,
                                    outerMargin: true,
                                    outerMarginTop: 10,
                                    outerMarginRight: 10,
                                    outerMarginBottom: 10,
                                    outerMarginLeft: 10,
                                    useTransformPositioning: true,
                                    mobileBreakpoint: 640,
                                    minCols: 5,
                                    maxCols: 100,
                                    minRows: 5,
                                    maxRows: 100,
                                    maxItemCols: 100,
                                    minItemCols: 1,
                                    maxItemRows: 100,
                                    minItemRows: 1,
                                    maxItemArea: 2500,
                                    minItemArea: 1,
                                    defaultItemCols: 1,
                                    defaultItemRows: 1,
                                    fixedColWidth: null,
                                    fixedRowHeight: null,
                                    keepFixedHeightInMobile: false,
                                    keepFixedWidthInMobile: false,
                                    scrollSensitivity: 10,
                                    scrollSpeed: 20,
                                    enableEmptyCellClick: false,
                                    enableEmptyCellContextMenu: false,
                                    enableEmptyCellDrop: false,
                                    enableEmptyCellDrag: false,
                                    enableOccupiedCellDrop: false,
                                    emptyCellDragMaxCols: 50,
                                    emptyCellDragMaxRows: 50,
                                    ignoreMarginInRow: false,
                                    draggable: {
                                        enabled: false,
                                    },
                                    resizable: {
                                        enabled: false,
                                    },
                                    swap: false,
                                    pushItems: true,
                                    disablePushOnDrag: false,
                                    disablePushOnResize: false,
                                    pushDirections: { north: true, east: true, south: true, west: true },
                                    pushResizeItems: false,
                                    displayGrid: DisplayGrid.OnDragAndResize,
                                    disableWindowResize: false,
                                    disableWarnings: false,
                                    scrollToNewItems: false,
                                };

                                dashboard.gridOptions.gridType = dashboard_info.data.gridType
                                dashboard.gridOptions.margin = dashboard_info.data.margin
                                dashboard.gridOptions.outerMarginTop = dashboard_info.data.outerMargin
                                dashboard.gridOptions.outerMarginRight = dashboard_info.data.outerMargin
                                dashboard.gridOptions.outerMarginBottom = dashboard_info.data.outerMargin
                                dashboard.gridOptions.outerMarginLeft = dashboard_info.data.outerMargin
                                dashboard.gridOptions.mobileBreakpoint = dashboard_info.data.mobileBreakpoint
                                dashboard.gridOptions.minColumns = dashboard_info.data.minColumns
                                dashboard.gridOptions.maxColumns = dashboard_info.data.maxColumns
                                dashboard.gridOptions.minRows = dashboard_info.data.minRows
                                dashboard.gridOptions.maxRows = dashboard_info.data.maxRows

                                dashboard.style = { roundedCorners: dashboard_info.data.roundedCorners, backgroundColor: dashboard_info.data.backgroundColor }

                                dashboard.owner = org.id

                                if(dashboard_info.data.copyFrom){
                                    dashboard.widgets = dashboard_info.data.copyFrom
                                }
                                this.analyticService.copyTo(dashboard).then(
                                    (res) => {
                                        this._matSnackBar.open(`New dashboard created`, 'Ok!', {
                                            verticalPosition: 'top',
                                            duration: 2000
                                        });
                                    }
                                )
                            }
                        )
                    }
                }
            );
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------



    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
