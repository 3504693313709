<h1 mat-dialog-title>{{'FORMS.CRUD.confirm_delete' | transloco}} {{data.name}}</h1>
<div mat-dialog-content>
  <p>{{'FORMS.CRUD.warning_cruscotto_delete' | transloco}}</p>
</div>
<div mat-dialog-actions>
    <button mat-button [mat-dialog-close]="undefined" (click)="dialogClose(undefined)">{{'FORMS.cancel' | transloco}}</button>
    <button
    mat-flat-button
    [color]="'primary'" [mat-dialog-close]="true" (click)="dialogClose(true)" color="warn" cdkFocusInitial>{{'FORMS.delete' | transloco}}</button>
  </div>

<!-- <div mat-dialog-actions class="p-16 pb-0 m-0" fxLayout="row" fxLayoutAlign="space-around center">
  <button mat-button (click)="onNoClick()" cdkFocusInitial>'ORG_DELETE_WARNING.CANCEL'</button>

  <button class="mr-8" mat-raised-button type="button" color="warn" (click)="this.dialogRef.close('yes')">
    'ORG_DELETE_WARNING.DELETE'
  </button>
</div> -->
