import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FuseNavigationService } from '@fuse/components/navigation';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { AnalyticService } from 'app/core/analytic/analytic.service';
import { DashboardInterface } from 'app/core/analytic/dashboard.model';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { Navigation } from 'app/core/navigation/navigation.types';
import { OrgService } from 'app/core/org/org.service';
import { UserService } from 'app/core/user/user.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector     : 'material-layout',
    templateUrl  : './material.component.html',
    encapsulation: ViewEncapsulation.None
})
export class MaterialLayoutComponent implements OnInit, OnDestroy
{
    public selectedOrg: any;
    public selectorFilterEnabled: boolean = false
    public timeFilterEnabled: boolean = false
    public dashboard: any
    isScreenSmall: boolean;
    navigation: Navigation;
    public isAdmin: boolean = false
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _navigationService: NavigationService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService,
        private _orgService: OrgService,
        private _analyticService: AnalyticService,
        private _userService: UserService,
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number
    {
        return new Date().getFullYear();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Subscribe to navigation data
        this._navigationService.navigation$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((navigation: Navigation) => {
                this.navigation = navigation;
            });

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({matchingAliases}) => {

                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');
            });


        this._orgService.org
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(
            (org) => {
                this.selectedOrg = org;
            }
        );

        this._analyticService.selectedDashboard.subscribe(
            (res: DashboardInterface) => {
                this.selectorFilterEnabled = res.selectorFilter.selectorFilterEnabled
                this.timeFilterEnabled = res.timeFilter.timeFilterEnabled
                this.dashboard = res
            }
        )

        this._userService.profile
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(
            (res) => {
                if(res.claim.hasOwnProperty('admin')){
                    this.isAdmin = true;
                }
            }
        );
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name: string): void
    {
        // Get the navigation
        const navigation = this._fuseNavigationService.getComponent<any>(name);

        if ( navigation )
        {
            // Toggle the opened status
            navigation.toggle();
        }
    }
}
