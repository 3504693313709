import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import * as Sentry from "@sentry/angular";
import { Integrations } from "@sentry/tracing"
import { environment } from 'environments/environment';
import { AppModule } from 'app/app.module';

if ( environment.production )
{
    enableProdMode();
}


Sentry.init({
    dsn: environment.sentryConfig.dns,
    autoSessionTracking: true,
    release: "platform@" + environment.version,
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: environment.sentryConfig.origins,
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
      // new TracingIntegrations.BrowserTracing()
    ],
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});


Sentry.setTag("env", environment.name);

platformBrowserDynamic().bootstrapModule(AppModule)
                        .catch(err => console.error(err));
