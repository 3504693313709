import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { NgSelectComponent } from '@ng-select/ng-select';
import { AnalyticService } from 'app/core/analytic/analytic.service';
import { DashboardInterface } from 'app/core/analytic/dashboard.model';
import { Subject } from 'rxjs';
import * as Sentry from "@sentry/angular";


@Component({
    selector: 'community-manager-selector',
    templateUrl: './community_manager_selector.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./community_manager_selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'manager-selector'
})
@Sentry.TraceClassDecorator()
export class CommunityManagerSelectorComponent implements OnInit, OnDestroy {

    @Input() dashboard: DashboardInterface = {};

    public pods: any = []
    public selectedPods: any


    public values: any = []
    public selectedValue: any

    private _unsubscribeAll: Subject<any> = new Subject<any>();
    // Access ng-select
    @ViewChild(NgSelectComponent) ngSelectComponent: NgSelectComponent;

    constructor(private _analyticService: AnalyticService,
        public router: Router, private _changeDetectorRef: ChangeDetectorRef) {
        // this.selectedPods = [{community: "enrico-mattei"}]
    }

    ngOnInit(): void {

    }

    ngOnChanges(changes: SimpleChanges): void {
        if ('dashboard' in changes && changes.dashboard.currentValue) { //&& (this.pods.length == 0 || changes.dashboard.currentValue.sisense_dashboard_id != this.dashboard.sisense_dashboard_id)
            this.dashboard = changes.dashboard.currentValue
            this.values = []
            if(this.ngSelectComponent){
                this.ngSelectComponent.close()
                // Call to clear
                this.ngSelectComponent.handleClearClick();
            }


            this._analyticService.getSelectorList(this.dashboard.selectorFilter.selector, this.dashboard.selectorFilter.grouping, this.dashboard.datasource).then(
                (res: any) => {
                    res?.values?.forEach(el => {
                        let entry = { label: el[0], value: el[0] }
                        el[1] ? entry['grouping'] = el[1] : null;

                        this.values.push(entry)
                    });

                    this.values = [... this.values]
            
                    if (this.values !== []) {
                        this._analyticService.podsSelection.next(this.values)
                    }
                    this._changeDetectorRef.detectChanges();
                }
            )
        } else {
            this.dashboard = {}
        }
    }

    /**
    * On destroy
    */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    onChange($event): any {
        // if($event.data && $event.data.pod.length > 0){
        let values = []
        $event.forEach(el => {
            if ('value' in el) {
                values.push(el.value)
            } else {
                this.values.filter(temp_pod => temp_pod.grouping.includes(el.grouping)).forEach(pod => {
                    values.push(pod.value)
                });
            }



        });
        if (values.length == 0) {
            this.values.forEach(pod => {
                values.push(pod.value)
            });

        }

        if (values !== []) {
            this._analyticService.podsSelection.next(values)
        } else {
            setTimeout(() => { this.onChange($event) }, 1000);
        }
    }
}
