import {
    FormStyle, getLocaleDayNames, getLocaleMonthNames, TranslationWidth
} from '@angular/common';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { EventManager } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { AnalyticService } from 'app/core/analytic/analytic.service';
import * as moment from 'moment'; // add this 1 of 4
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import * as Sentry from "@sentry/angular";



@Component({
    selector: 'date-selector',
    templateUrl: './date_selector.component.html',
    encapsulation: ViewEncapsulation.None,
    exportAs: 'dateSelector'
})
@Sentry.TraceClassDecorator()
export class DateSelectorComponent implements OnInit, OnDestroy {

    selected: any

    stored_dates: any

    //selected: any;
    alwaysShowCalendars: boolean;
    ranges: any = {
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    }

    locale = {}

    invalidDates: moment.Moment[] = [moment().add(2, 'days'), moment().add(3, 'days'), moment().add(5, 'days')];

    isInvalidDate = (m: moment.Moment) => {
        return this.invalidDates.some(d => d.isSame(m, 'day'))
    }

    maxDate: any
    minDate: any

    // @ViewChild('calendar') matInput: ElementRef;
    //@ViewChild(DaterangepickerDirective, { static: true }) pickerDirective: DaterangepickerDirective;

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(private _analyticService: AnalyticService,
        public router: Router, private _translocoService: TranslocoService,
        private eventManager: EventManager) {
        this.alwaysShowCalendars = true;
        this.selected = {
            startDate: moment().subtract(6, 'days'),
            endDate: moment()
            // startDate: moment('2020-11-18T00:00Z'),
            // endDate: moment('2020-11-26T00:00Z')
        }

        this.stored_dates = {
            startDate: moment().subtract(6, 'days'),
            endDate: moment()
        }

        this.maxDate = moment().add(6, 'days')
        this.minDate = moment("2020-01-01", "YYYY-MM-DD")
    }

    ngOnInit(): void {
        this._analyticService.selectedDashboard
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                (res) => {
                    this._analyticService.updateFilters(this.stored_dates)
                }
            )

        this._translocoService.langChanges$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((activeLang) => {
                this._translocoService.selectTranslateObject('TOOLBAR.TIMEFILTER.RANGES').pipe(take(1))
                    .subscribe((translation) => {

                        let temp_ranges = {
                            [translation['Today']]: [moment(), moment()],
                            [translation['Yesterday']]: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                            [translation['Last 7 Days']]: [moment().subtract(6, 'days'), moment()],
                            [translation['Last 30 Days']]: [moment().subtract(29, 'days'), moment()],
                            [translation['This Month']]: [moment().startOf('month'), moment().endOf('month')],
                            [translation['Last Month']]: [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                        }

                        this.ranges = temp_ranges

                        let months_names = getLocaleMonthNames(activeLang,
                            FormStyle.Standalone,
                            TranslationWidth.Wide);
                        let days_names = getLocaleDayNames(activeLang,
                            FormStyle.Standalone,
                            TranslationWidth.Wide)

                        days_names = days_names.map(day => { return day.substr(0, 2) })//.forEach(day => day = day.substr(0,2))

                        this.locale = { applyLabel: 'ok', displayFormat: 'DD-MM-YYYY', firstDay: 1, monthNames: months_names, daysOfWeek: days_names }
                    });
            })


        this.eventManager.addGlobalEventListener(
            'document',
            'keydown',
            (ev) => {
                if (ev.key == 'ArrowLeft') {
                    this.back()
                } else if (ev.key == 'ArrowRight') {
                    this.forward()
                }
            }
        );
    }
    /**
* On destroy
*/
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }


    // handleCalendarClick(e) {
    //     // if(this.matInput.nativeElement.hasFocus()){
    //     //     this.matInput.nativeElement.blur()
    //     // } else {
    //         // this.matInput.nativeElement.click()
    //         this.pickerDirective.toggle(e)
    //         this.pickerDirective.picker.show()
    //         this.pickerDirective.open(e)
    //     // }
    // }

    updatedFilter(event) {

        if (event.startDate !== null && event.endDate !== null) {
            this.selected = { startDate: moment(event.startDate), endDate: moment(event.endDate) }
            this.stored_dates = { startDate: event.startDate, endDate: event.endDate }
            this._analyticService.updateFilters(event)
        }

    }

    back(): any {
        // this.selected = {startDate: this.selected.startDate.subtract(this.selected.startDate.diff(this.selected.endDate, 'days'), 'days'), endDate: this.selected.startDate}
        let startDate = moment(this.selected.startDate._d)
        let endDate = moment(this.selected.endDate._d)
        startDate = startDate.subtract(Math.abs(startDate.diff(endDate, 'day') - 1), 'days')
        endDate = moment(this.selected.startDate._d).subtract(1,"days")
        
        this.selected = { startDate: moment(startDate), endDate: moment(endDate) }
        this.stored_dates = { startDate: moment(startDate), endDate: moment(endDate) }
        setTimeout(
            () => this._analyticService.updateFilters(this.stored_dates),
            0
        )
    }

    forward(): any {
        let startDate = moment(this.selected.startDate._d)
        let endDate = moment(this.selected.endDate._d)

        endDate = endDate.add(Math.abs(startDate.diff(endDate, 'day')) + 1, 'days')
        startDate = moment(this.selected.endDate._d).add(1,"days")

        this.selected = { startDate: startDate, endDate: endDate }
        this.stored_dates = { startDate: moment(startDate), endDate: moment(endDate) }
        setTimeout(
            () => this._analyticService.updateFilters(this.stored_dates),
            0
        )
        // this._analyticService.updateFilters(this.selected)

    }
}
