import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AnalyticService } from 'app/core/analytic/analytic.service';
import { UserService } from 'app/core/user/user.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as Sentry from "@sentry/angular";

@Component({
    selector       : 'edit-dashboard',
    templateUrl    : './edit_dashboard.component.html',
    encapsulation  : ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.Default,
    exportAs       : 'edit-dashboard'
})
@Sentry.TraceClassDecorator()
export class EditDashboardComponent implements OnInit, OnDestroy
{
    isAdmin: boolean = false;

    private _unsubscribeAll: Subject<any> = new Subject<any>();
    /**
     * Constructor
     */
    constructor(
        public _analyticService: AnalyticService,
        public router: Router,
        private _userService: UserService,
    )
    {
    }

    toggleEditDashboard(){
        // const drawer = this._fuseDrawerService.getComponent('dashboardDrawer');
        // drawer.toggle();
        this._analyticService.editDashboard.next(null);
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        this._userService.profile
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(
            (res) => {
                if(res.claim.hasOwnProperty('admin')){
                    this.isAdmin = true;
                }
            }
        );
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

}
