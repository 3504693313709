import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
// import { SentryService } from '../monitoring/sentry/sentry.service';
import { DateAdapter } from '@angular/material/core';
import { FuseNavigationService } from '@fuse/components/navigation';
import { TranslocoService } from '@ngneat/transloco';
import { User } from 'app/core/user/user.types';
import { environment } from 'environments/environment';
import { isEqual, omit } from 'lodash';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { distinctUntilChanged, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { AnalyticService } from '../analytic/analytic.service';
import { ModulesService } from '../modules/modules.service';
import { SentryService } from '../monitoring/sentry.service';
import { OrgService } from '../org/org.service';

@Injectable()
export class UserService implements OnDestroy {
    public _fUserID$: ReplaySubject<any> = new ReplaySubject<any>(1);
    public profile: Observable<any>;
    private _user: ReplaySubject<User> = new ReplaySubject<User>(1);

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    uid: any
    user_org_id: any
    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient,
        private afs: AngularFirestore,
        private orgService: OrgService,
        private analyticService: AnalyticService,
        private modulesService: ModulesService,
        private adapter: DateAdapter<any>,
        private _fuseNavigationService: FuseNavigationService,
        private _translocoService: TranslocoService,
        private sentryService: SentryService,) {
        /**
         * Subscribe to user id value change and return an Observable with the current user value
         */
        this.profile = this._fUserID$
            .pipe(takeUntil(this._unsubscribeAll))
            .pipe(
                switchMap((uid) => {
                    if (uid !== '') {
                        this.uid = uid
                        // debugger;
                        return afs.collection('users').doc(uid).valueChanges()
                            .pipe(takeUntil(this._unsubscribeAll))
                            .pipe(distinctUntilChanged((prev: any, curr) => {
                                return isEqual(omit(prev, ['lang']), omit(curr, ['lang']))
                            }))
                    } else {
                        return Promise.reject('Profile ID not set');
                    }
                })
            );

        this.profile
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                (profile: any) => {

                    if (profile === undefined) {
                        return;
                    }

                    if (profile.lang) {
                        this._translocoService.setActiveLang(profile.lang);
                        this.adapter.setLocale(profile.lang);
                    } else {
                        this._translocoService.setActiveLang('en');
                        this.adapter.setLocale('en');
                    }


                    this.user_org_id = profile.org
                    let stored_org = this.orgService.storedOrg

                    // Set the correct sentry tags for this user
                    this.sentryService.setTag('user.email', profile.email);
                    this.sentryService.setTag('user.org', profile.org);

                    if(profile.email == 'zeno.trevisan@hivepower.tech'){
                        this.addTools()
                    }

                    if (stored_org != {} && stored_org && profile.claim.hasOwnProperty('admin')) {
                        this.orgService._fOrgID$.next(stored_org.id);
                        this.analyticService._fOwnerID$.next(stored_org.id);
                        this.addAdministrationMenu(stored_org.id);
                        this.modulesService.isAdmin = true
                    } else {
                        this.orgService._fOrgID$.next(profile.org);
                        this.analyticService._fOwnerID$.next(profile.org);
                        if (profile.claim.hasOwnProperty('admin')) {
                            this.addAdministrationMenu(profile.org);
                            this.modulesService.isAdmin = true
                        } else {
                            this.modulesService.isAdmin = false
                        }


                    }
                }
            );
    }

    public addTools(): any {
                const controllersGroup: any = {
                    id: 'tools',
                    title: 'Tools',
                    type: 'group',
                    children: [
                        {
                            id: 'tools-page',
                            title: 'Tools',
                            type: 'basic',
                            icon: 'settings',
                            link: '/admin/tools'
                        }
                    ]
                }

                const tempNavigation: any =
                    this._fuseNavigationService.getComponent('mainNavigation');

                tempNavigation.navigation.push(controllersGroup);

                tempNavigation.refresh();
            
    }

    public addAdministrationMenu(orgID): any {

        this._translocoService.selectTranslateObject('NAVIGATION').pipe(take(1))
            .subscribe((translation) => {

                const controllersGroup: any = {
                    id: 'admin',
                    title: translation['Administration'],
                    type: 'group',
                    icon: 'settings',
                    children: [
                        {
                            id: 'admin.org.settings',
                            title: `${orgID} settings`,
                            type: 'basic',
                            link: '/admin/settings'
                        },
                        {
                            id: 'admin.monitoring',
                            title: translation['Monitoring'],
                            type: 'group',
                            icon: 'heroicons_outline:chart-bar',
                            children: [
                                {
                                    id: 'admin.monitoring.sentry',
                                    title: 'Sentry',
                                    type: 'basic',
                                    link: environment.sentryConfig.websiteURL,
                                    externalLink: true
                                },
                                {
                                    id: 'admin.monitoring.datadog',
                                    title: 'Datadog',
                                    type: 'basic',
                                    link: environment.datadogConfig.websiteURL,
                                    externalLink: true
                                },
                                {
                                    id: 'admin.monitoring.bugherd',
                                    title: 'Bugherd',
                                    type: 'basic',
                                    link: environment.bugherdConfig.websiteURL,
                                    externalLink: true
                                },
                                {
                                    id: 'admin.monitoring.github',
                                    title: 'Github',
                                    type: 'basic',
                                    link: environment.githubConfig.websiteURL,
                                    externalLink: true
                                }
                            ]
                        }
                    ]
                };

                const tempNavigation: any =
                    this._fuseNavigationService.getComponent('mainNavigation');

                const personalDashboardIndex = tempNavigation.navigation.findIndex(
                    (x: any) => x.id === 'admin'
                );

                if (personalDashboardIndex !== -1) {
                    tempNavigation.navigation[personalDashboardIndex] = controllersGroup;
                } else {
                    tempNavigation.navigation.push(controllersGroup);
                }

                tempNavigation.refresh();
            })




    }

    // -----------------------------------------s------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for user
     *
     * @param value
     */
    set user(value: User) {
        // Store the value
        this._user.next(value);
    }

    get user$(): Observable<User> {
        return this._user.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
 * Get the current logged in user data
 */
    get(): Observable<User> {
        return this._httpClient.get<User>('api/common/user').pipe(
            tap((user) => {
                this._user.next(user);
            })
        );
    }

    /**
     * Update the user
     *
     * @param user
     */
    update(user: User): Observable<any> {
        return this._httpClient.patch<User>('api/common/user', { user }).pipe(
            map((response) => {
                // Execute the observable
                this._user.next(response);
            })
        );
    }

    setToMyOrg() {
        this.orgService._fOrgID$.next(this.user_org_id);
        this.analyticService._fOwnerID$.next(this.user_org_id);
        this.addAdministrationMenu(this.user_org_id);
    }

    async updateUser(user) {
        const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${this.uid}`);
        const userData: any = user

        await userRef.set(userData, {
            merge: true
        })

        return userRef
    }


    /**
 * On destroy
 */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
