

import { Injectable } from "@angular/core";
import * as Sentry from "@sentry/angular";

@Injectable({
  providedIn: 'root'
})
export class SentryService {

  transactions: any[] = new Array()
  constructor(
  ) {
  }

  setTag(name, value){
    Sentry.setTag(name, value);
  }

  registerTransaction(status: string, name: string, scope: string, id?: any){

    if(status == 'start'){
      // This will create a new Transaction for you
      const transaction = Sentry.startTransaction({ name: name });
      // set the transaction on the scope so it picks up any errors
      // hub.configureScope(scope => scope.setSpan(transaction));

      // Assume this function makes an xhr/fetch call
      // const result = validateShoppingCartOnServer();

      this.transactions.push(transaction)

      return transaction.traceId
    } else if(status == 'end'){

      const transaction = this.transactions.find(temp => temp.traceId == id)
      // const span = transaction.startChild({
      //   data: {
      //     result
      //   },
      //   op: 'task',
      //   description: `processing shopping cart result`,
      // });
      // processAndValidateShoppingCart(result);
      // span.finish();
  
      transaction?.finish();

      // console.log(this.transactions)
    }


  }
}