<h1 mat-dialog-title>{{'TOOLBAR.ADD_ELEMENT.new_dashboard' | transloco}}</h1>
<div mat-dialog-content>
    <!-- <form [formGroup]="save_dashboard_form" class="w-100-p">
        <formly-form [form]="save_dashboard_form" [fields]="save_dashboard_fields" 
            [model]="save_dashboard_model"></formly-form>
    </form> -->

    <!-- <ng-select class="flex fuse-mat-dense flex-auto min-w-40" appendTo="body" [items]="dashboards_list"
        [(ngModel)]="selected_dashboard" [clearable]="false" [searchable]="true" bindLabel="owner" bindValue="owner"
        (change)='copyDashboard($event)'>

        <ng-template ng-option-tmp let-item="item" let-index="index">
            <div class="flex gap-4">
                <span>{{item.tab_info.title}} ¦¦ {{item.owner}}</span>
            </div>

        </ng-template>
    </ng-select> -->

    <formio #newDashboardForm [form]='dashboard_form' [refresh]="data_refresh" (submit)="newDashboard($event)"
        [submission]="dashboard_data" (change)="onFormChange($event)">
    </formio>
</div>
<!-- <div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="undefined">{{'FORMS.cancel' | transloco}}</button>
  <button
  mat-flat-button
  type="button"
  [color]="'primary'" (click)="submitForm()" [disabled]="this.dashboard_form.invalid" cdkFocusInitial>{{'FORMS.save' | transloco}}</button>
</div> -->