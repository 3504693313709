// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    name: 'production',
    production: true,
    version: require('../../package.json').version + '-prod',
    themeDefaultConfig: {
        layout: 'classic',
        scheme: 'auto',
        theme : 'default'
    },
    firebaseConfig: {
        apiKey: "AIzaSyCXumSQx8UZC6tJ9RaCXjpGqQ5YMTgjEYw",
        authDomain: "platform-3d345.firebaseapp.com",
        projectId: "platform-3d345",
        storageBucket: "platform-3d345.appspot.com",
        messagingSenderId: "524318251664",
        appId: "1:524318251664:web:04255bf75a7d10b7669615",
        measurementId: "G-9H732B6DMT"
    },
    auth0Config: {
        domain: 'auth.hivepower.tech',//'hivepower.eu.auth0.com', //auth.hivepower.tech
        clientId: 'HnrbAxEYC9gR4FU4Q0oJ34h8uKRAntrc',
        callbackUrl: 'https://platform.hivepower.tech/__/auth/handler',
        realm: 'Username-Password-Authentication',
        apiAudience: 'https://hivepower.eu.auth0.com/api/v2/',
        //"scope":"read:current_user update:current_user_metadata"
        scope: 'openid email'
    },
    sentryConfig: {
        websiteURL: 'https://sentry.io/organizations/hive-power-sagl/projects/hive-platform/?project=5599371',
        dns : 'https://7ace227d88c741e48128068cde59d758@o507789.ingest.sentry.io/5599371',
        origins: [ 'https://platform-dev.hivepower.tech', 'https://platform.hivepower.tech', 'http://localhost:4200', 'http://localhost'], //
        tracesSampleRate: 1.0
    },
    datadogConfig: {
        websiteURL: 'https://app.datadoghq.eu/dashboard/lists',
        applicationId: '98111b5a-97ae-40ad-b34c-ec94191bb3bf',
        clientToken: 'pub92ec21e9d28f944cb99a5a23d70cd050',
        site: 'datadoghq.eu',
        service:'hive-platform',
        // Specify a version number to identify the deployed version of your application in Datadog
        // version: '1.0.0',
        sampleRate: 100,
        trackInteractions: true
    },
    bugherdConfig:{
        websiteURL: 'https://www.bugherd.com/projects/224644/tasks/49'
    },
    sisenseConfig: {
        server: 'https://sisense.flexo.energy',
        scriptTagId: 'sisenseScript',
        dashboardGalleryPrefix: '_gallery',
        skippedWidgetTypes: ['richtexteditor'],
        tagId: 'sisenseScript',
        logout: 'https://sisense.flexo.energy/api/v1/authentication/logout',
        urls: {
            dahboardList: '/api/v1/dashboards?fields=oid%2Ctitle%2Cdatasource%2Cdesc%2Cfilters%2CdefaultFilters%2Clayout%2CpreviewLayout&expand=widgets(oid%2Ctitle)',
            groupList: '/api/v1/groups'
        }
    },
    githubConfig: {
        websiteURL: 'https://github.com/hivepower/platform-app-root'
    },
    hasuraConfig: {
        uri: "https://drainspotter-dev.hasura.app/v1/graphql/",
        endpoints: {
            enegrySpliQuarters: "aem_community_energy_split_quarter_formatted"
        }
    },
    modules:{
        version: 'v1',
        apiSmartPricerVersion: 'v1',
        apiCommunityManagerVersion: 'v1',
    },
    logoutUrl: 'https://platform.hivepower.tech/',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
