/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs-compat/Observable';

@Injectable()
export class FormsService{
    /**
     * Constructor
     */
    constructor(
        private afs: AngularFirestore
    ) {

    }

    getForm(name: string): Observable<any>{
        return this.afs.collection('formio_schemas').doc(name).get();
    }

}
