<ng-select *ngIf="this.router.url.includes('analytic') && this.values.length > 0" class="fuse-mat-dense flex flex-grow"
    appendTo="body" [items]="this.values" bindLabel="value" bindValue="value" groupBy="grouping" [closeOnSelect]="false"
    [selectableGroup]="true" [placeholder]="'TOOLBAR.COMMUNITY_MANAGER_SELECTOR.all_pods' | transloco"
    [(ngModel)]="selectedValue" [clearable]="false" [multiple]="true" [searchable]="true" (change)="onChange($event)">
    <ng-template ng-option-tmp let-item="item" let-index="index" let-search="owner">
        <div class="flex gap-4">
            <span>{{item.value}}</span>
        </div>

    </ng-template>
</ng-select>