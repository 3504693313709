import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class OrgSelectService
{

    /**
     * Constructor
     */
    constructor(private firestore: AngularFirestore)
    {
    }

    snapshotOrgs(): Observable<any>{
        return this.firestore.collection('orgs').snapshotChanges()
        // .pipe(distinctUntilChanged((prev: any, curr) => isEqual(prev?.payload?.data(), curr?.payload?.data())));
    }

}
