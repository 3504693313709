/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/naming-convention */
import { DOCUMENT } from '@angular/common';
import { EventEmitter, Inject, Injectable, Injector, OnDestroy } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { FuseNavigationService } from '@fuse/components/navigation';
import { FuseSplashScreenService } from '@fuse/services/splash-screen';
import { TranslocoService } from '@ngneat/transloco';
import { CompactType, DisplayGrid, GridType } from 'angular-gridster2';
import { environment } from 'environments/environment';
import { isEqual } from 'lodash';
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment } from 'moment';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { distinctUntilChanged, switchMap, take, takeUntil } from 'rxjs/operators';
import streamSaver from 'streamsaver';
import { UserService } from '../user/user.service';
import { ConfirmDashboardDeleteDialog } from './confirm_dashboard_delete/confirm-dashboard-delete.dialog';


declare const prism: any;



const moment = _rollupMoment || _moment;


@Injectable()
export class AnalyticService implements OnDestroy {

    public _fOwnerID$: ReplaySubject<any> = new ReplaySubject<any>(1);
    public dashboards: Observable<any>;
    public filter: EventEmitter<any> = new EventEmitter<any>();
    public podsSelection: EventEmitter<any> = new EventEmitter<any>();

    public selectedDashboard: EventEmitter<any> = new EventEmitter<any>();
    public editDashboard: EventEmitter<any> = new EventEmitter<any>();
    public reloadDashboard: EventEmitter<any> = new EventEmitter<any>();

    public menu_dashboards: any;
    sisense_app: any;
    connection_in_progress: boolean = false;
    public sDashboard: any;


    private _unsubscribeAll: Subject<any> = new Subject<any>();
    /**
     * Constructor
     */
    constructor(
        private afs: AngularFirestore,
        // private sentryService: SentryService,
        private _fuseNavigationService: FuseNavigationService,
        @Inject(DOCUMENT) private document,
        private injector: Injector,
        private _router: Router,
        private _transloco: TranslocoService,
        private _matSnackBar: MatSnackBar,
        public dialog: MatDialog,
        private splashScreenServcie: FuseSplashScreenService,
        private _translocoService: TranslocoService,) {
        /**
         * Subscribe to user id value change and return an Observable with the current user value
         */
        this.dashboards = this._fOwnerID$.pipe(
            switchMap((uid) => {
                if (uid !== '') {
                    return afs.collection('dashboards', ref => ref.where('owner', '==', uid)).snapshotChanges()
                        .pipe(takeUntil(this._unsubscribeAll))
                        .pipe(distinctUntilChanged((prev: any, curr) => {
                            if (prev.length !== curr.length) {
                                return false;
                            }

                            let res = true;
                            prev.some((el, index) => {
                                const diff = !isEqual(el.payload?.doc?.data(), curr[index].payload?.doc?.data()) || !isEqual(el.payload?.doc?.data().dashboard, curr[index].payload?.doc?.data().dashboard);
                                if (diff) {
                                    res = false;
                                    return false;
                                }
                            });
                            return res;
                        }));
                } else {
                    return Promise.reject('Org ID not set');
                }
            })
        );

        this.dashboards
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                (dashboards: any) => {
                    if (dashboards === undefined || dashboards === []) {
                        return;
                    }



                    const menuDashboard = [];

                    dashboards.forEach((tempDashboard) => {
                        const dashboard = {
                            id: tempDashboard.payload.doc.id,
                            ...tempDashboard.payload.doc.data(),
                        };

                        const title = dashboard.title ? dashboard.title[this._translocoService.getActiveLang()] : '';

                        menuDashboard.push({
                            id: dashboard.id,
                            title: title ? title : dashboard.title['en'],
                            translations: dashboard.title,
                            type: 'basic',
                            icon: dashboard.icon,
                            link: '/analytic/' + dashboard.id,
                            index: dashboard.index === undefined || dashboard.index === null ? 1000 : dashboard.index,
                        });
                    });

                    menuDashboard.sort((a, b) => (a.index < b.index ? -1 : 1));

                    this.menu_dashboards = menuDashboard;

                    this._transloco.selectTranslate('NAVIGATION.Modules')
                        .pipe(takeUntil(this._unsubscribeAll))
                        .subscribe((translation) => {
                            const personalDashboardGroup: any = {
                                id: 'modules',
                                title: translation,
                                translate: 'NAV.MODULES',
                                type: 'group',
                                icon: 'dashboard',
                                children: menuDashboard,
                            };

                            const tempNavigation: any =
                                this._fuseNavigationService.getComponent('mainNavigation');

                            if (tempNavigation.navigation === null) {
                                tempNavigation.navigation = personalDashboardGroup;
                            } else {
                                const personalDashboardIndex = tempNavigation.navigation.findIndex(
                                    (x: any) => x.id === 'modules'
                                );

                                if (personalDashboardIndex !== -1) {
                                    tempNavigation.navigation[personalDashboardIndex] = personalDashboardGroup;
                                } else {
                                    tempNavigation.navigation.unshift(personalDashboardGroup);
                                }
                            }
                            tempNavigation.refresh();
                        });


                }


            );

    }

    initSisense(): Promise<any> {
        return new Promise((resolve, reject) => {

            if (this.sisense_app !== undefined && this.connection_in_progress) {
                resolve(true);
                return;
            } else {
                this.connection_in_progress = true;
            }

            this.addSisenseJS()
                .then(
                    () => {
                        console.log('SisenseJS loaded')
                        // debugger
                        // alert("stop")
                        this.connect()
                            .then(
                                (app) => {
                                    this.sisense_app = app;
                                    this.splashScreenServcie.hide();
                                    // this.sisenseGlobalization()
                                    resolve(true);
                                }
                            )
                            .catch(
                                (error) => {
                                    console.error('error', error);

                                    reject;
                                }
                            );
                    }).catch(
                        (error) => {
                            console.log('error', error);
                            this._matSnackBar.open('Failed to load SiSense', 'Ok!', {
                                verticalPosition: 'top',
                                duration: 2000
                            });
                            reject;
                        });
        });
    }

    addSisenseJS(): Promise<any> {
        return new Promise((resolve, reject) => {
            const script = this.document.createElement('script');
            script.type = 'text/javascript';
            script.src = environment.sisenseConfig.server + '/js/sisense.js';
            script.onload = (): any => {
                console.log('SisenseJS loaded');
                // debugger; 
                console.log(window.Sisense); // Check if Sisense is available on the window object
                resolve(true);
            };
            script.onerror = (error) => reject(new Error('Failed to load Sisense.js'));

            this.document.getElementsByTagName('head')[0].appendChild(script);
            // debugger; 
        });
    }

    connect(): Promise<any> {
        this.fixRewrite();
        console.log('Sisense.connect', window)
        return new Promise((resolve, reject) => {
            Sisense.connect(environment.sisenseConfig.server, false).then((app) => {
                console.log('Sisense connected');
                this.sisense_app = app;


                const userService = this.injector.get(UserService);

                userService.profile
                    .pipe(
                        take(1),
                        takeUntil(this._unsubscribeAll)
                    )
                    .subscribe(
                        (profile) => {
                            if (prism.user.email !== profile.email) {
                                debugger;
                                this._router.navigate(['/sign-out']);
                            }
                        }
                    );
                resolve(this.sisense_app);
            })
                .catch((error) => {
                    console.error('Sisense connection error', error);
                    reject(error);
                }
                );

        });

    }

    //  Fix for angular rewrite module
    fixRewrite(): void {

        let urlChangeCallback;

        //  The (<any>window) part just lets us reference window.prism (otherwise typescript throws a messaging saying undefined)
        (window as any).prism.sisenseAngular
            .module('standalone')
            .config(['$provide', ($provide) => {
                $provide.decorator('$browser', ['$delegate', ($delegate) => {
                    const { onUrlChange } = $delegate;

                    $delegate.url = () => window.location.href;
                    $delegate.onUrlChange = () => onUrlChange((newUrl, newState) => {
                        if (typeof urlChangeCallback === 'function') {
                            urlChangeCallback(newUrl, newState);
                        }
                    });

                    return $delegate;
                }]);
            }])
            .run(['$rootScope', '$location', '$browser', ($rootScope, $location, $browser) => {
                $rootScope.$$watchers.some((watcher, index, arr) => {
                    const exp = watcher.exp.toString();

                    if (exp.indexOf('$locationChangeStart') !== -1) {
                        arr.splice(index, 1);
                        return true;
                    }
                });

                function setBrowserUrlWithFallback(url, replace, state) {
                    const oldUrl = $location.url();
                    const oldState = $location.$$state;

                    try {
                        $browser.url(url, replace, state);
                        $location.$$state = $browser.state();
                    } catch (e) {
                        $location.url(oldUrl);
                        $location.$$state = oldState;

                        throw e;
                    }
                }

                function afterLocationChange(oldUrl, oldState) {
                    $rootScope.$broadcast(
                        '$locationChangeSuccess',
                        $location.absUrl(),
                        oldUrl,
                        $location.$$state,
                        oldState
                    );
                }

                urlChangeCallback = (newUrl, newState) => {
                    $rootScope.$evalAsync(() => {
                        const oldUrl = $location.absUrl();
                        const oldState = $location.$$state;

                        $location.$$parse(newUrl);
                        $location.$$state = newState;

                        const defaultPrevented = $rootScope.$broadcast(
                            '$locationChangeStart', newUrl, oldUrl, newState, oldState
                        ).defaultPrevented;

                        if ($location.absUrl() !== newUrl) {
                            return;
                        }

                        if (defaultPrevented) {
                            $location.$$parse(oldUrl);
                            $location.$$state = oldState;
                            setBrowserUrlWithFallback(oldUrl, false, oldState);
                        } else {
                            afterLocationChange(oldUrl, oldState);
                        }
                    });

                    if (!$rootScope.$$phase) {
                        $rootScope.$digest();
                    }
                };
            }]);
    }

    getDashboardList(): Promise<any> {
        return this.sisense_app.$$http.get(environment.sisenseConfig.urls.dahboardList);
    }

    getGroups(): Promise<any> {
        return this.sisense_app.$$http.get(environment.sisenseConfig.urls.groupList);
    }

    createGroup(name: string): Promise<any> {
        return this.sisense_app.$$http.post(environment.sisenseConfig.urls.groupList, { name: name });
    }

    async updateDashboardsOrder(orgId, dashboard_order): Promise<any> {
        const dashboards_ref = this.afs.collection('dashboards').ref;

        const dashbordsQuery: any = await dashboards_ref.where('owner', '==', orgId).get();

        if (dashbordsQuery.docs.length === dashboard_order.length) {

            for (let i = 0; i < dashboard_order.length; i++) {
                // dashboard_order[i].index = i;

                const id = dashboard_order[i].id;
                // delete dashboard_order[i].id;
                await dashboards_ref.doc(id).set({ index: i }, { merge: true });

            }
            return true;

        } else {
            this._matSnackBar.open('List lengths does not match!', 'Ok!', {
                verticalPosition: 'top',
                duration: 2000
            });
            console.error('Lists lengths does not match');
            return false;
        }
    }

    updateFilters(event) {

        let start_date = event.startDate._d;
        const end_date = event.endDate._d;
        const members = [];
        while (moment(start_date).format() <= moment(end_date).format()) {
            members.push(moment(start_date).format('YYYY-MM-DDTHH:mm:ss'));
            start_date = moment(start_date).add(1, 'days');
        }

        this.filter.next(members);
    }

    updatePodsSelection(pods) {

        this.podsSelection.next(pods);
    }

    updateDashboard(dashboard, tab_info, id) {
        const dashboardRef: AngularFirestoreDocument<any> = this.afs.doc(`dashboards/${id}`);

        const sisense_dashboard_id = tab_info.dashboard;
        const hide_date_filter = tab_info.hide_date_filter;
        const manager = tab_info.manager;
        delete tab_info.manager;
        delete tab_info.dashboard;
        delete tab_info.hide_date_filter;
        const dashboardData: any = { dashboard: dashboard, tab_info: tab_info, sisense_dashboard_id: sisense_dashboard_id, hide_date_filter: hide_date_filter, manager: manager }; //, datasource: datasource


        return dashboardRef.set(dashboardData, {
            merge: true
        });
    }

    copyTo(dashboard) {
        return this.afs.collection('dashboards').add(dashboard);
    }

    updateWidgets(id, widgets) {
        const dashboardRef: AngularFirestoreDocument<any> = this.afs.doc(`dashboards/${id}`);
        const dashboardData: any = { widgets: widgets };
        return dashboardRef.set(dashboardData, {
            merge: true
        });
    }

    updateDashboard2(dashboard, id) {
        const dashboardRef: AngularFirestoreDocument<any> = this.afs.doc(`dashboards/${id}`);

        return dashboardRef.set(dashboard, {
            merge: true
        });
    }

    getAllDashboards() {
        return this.afs.collection('dashboards').snapshotChanges();
        // valueChanges({ idField: 'id' })
    }

    createDashboard(dashboard_info, org_id) {

        const tab_info = {
            dashboard_type: 'Organization',
            icon: dashboard_info?.icon,
            organization_id: org_id,
            title: dashboard_info.title
        };

        const owner = org_id;

        const grid_options: any = {
            gridType: GridType.ScrollVertical,
            compactType: CompactType.None,
            margin: 10,
            outerMargin: true,
            outerMarginTop: 10,
            outerMarginRight: 10,
            outerMarginBottom: 10,
            outerMarginLeft: 10,
            useTransformPositioning: true,
            mobileBreakpoint: 640,
            minCols: 5,
            maxCols: 100,
            minRows: 5,
            maxRows: 100,
            maxItemCols: 100,
            minItemCols: 1,
            maxItemRows: 100,
            minItemRows: 1,
            maxItemArea: 2500,
            minItemArea: 1,
            defaultItemCols: 1,
            defaultItemRows: 1,
            fixedColWidth: null,
            fixedRowHeight: null,
            keepFixedHeightInMobile: false,
            keepFixedWidthInMobile: false,
            scrollSensitivity: 10,
            scrollSpeed: 20,
            enableEmptyCellClick: false,
            enableEmptyCellContextMenu: false,
            enableEmptyCellDrop: false,
            enableEmptyCellDrag: false,
            enableOccupiedCellDrop: false,
            emptyCellDragMaxCols: 50,
            emptyCellDragMaxRows: 50,
            ignoreMarginInRow: false,
            draggable: {
                enabled: false,
            },
            resizable: {
                enabled: false,
            },
            swap: false,
            pushItems: true,
            disablePushOnDrag: false,
            disablePushOnResize: false,
            pushDirections: { north: true, east: true, south: true, west: true },
            pushResizeItems: false,
            displayGrid: DisplayGrid.OnDragAndResize,
            disableWindowResize: false,
            disableWarnings: false,
            scrollToNewItems: false,
        };

        const dashboard = [];

        return this.afs.collection('dashboards')
            .add({ dashboard: dashboard, grid_options: grid_options, tab_info: tab_info, owner: owner, index: Number.MAX_SAFE_INTEGER, manager: dashboard_info.manager, sisense_dashboard_id: dashboard_info.dashboard, hide_date_filter: dashboard_info.hide_date_filter });



    }

    getWidgetList() {
        return new Promise((resolve, reject) => {
            const dashboardsUrl = '/api/v1/dashboards?fields=oid%2Ctitle&expand=widgets(oid%2Ctitle)';
            this.sisense_app.$$http.get(dashboardsUrl).then((response) => {

                resolve(response.data);

            });
        });
    }

    // getDatasourcesList() {
    //     return new Promise((resolve, reject) => {
    //         let dashboardsUrl = '/api/v1/elasticubes/getElasticubes'//'/api/v1/datasets';
    //         this.sisense_app.$$http.get(dashboardsUrl).then((response) => {

    //             resolve(response.data)

    //         })
    //     })
    // }

    getDatasourcesList() {
        return new Promise((resolve, reject) => {
            const elasticube = '/api/v1/elasticubes/live';
            const live = '/api/v1/elasticubes/getElasticubes';//'/api/v1/datasets';


            Promise.all([this.sisense_app.$$http.get(elasticube), this.sisense_app.$$http.get(live)]).then((values) => {
                let res = [];
                values.forEach((val) => {
                    res = res.concat(val.data);
                });

                resolve(res);
            });

        });
    }

    getDimensions(name: string): Promise<any> {
        return new Promise((resolve, reject) => {
            const dashboardsUrl = `/api/datasources/${name}/fields`; //'/api/v1/datasets';
            this.sisense_app.$$http.get(dashboardsUrl).then((response) => {

                resolve(response.data);

            });
        });
    }

    getPodsList(datasource) {


        return new Promise((resolve, reject) => {
            const jaql =
            {
                'metadata': [
                    {
                        'dim': '[energy_split.PoD]',
                        'sort': 'asc'
                    },
                    {
                        'dim': '[energy_split.community_id]',
                        'filter': {
                            'explicit': true,
                            'multiSelection': false,
                            'members': []
                        }
                    }
                ]
            };

            const dashboardsUrl = `/api/datasources/${datasource}/jaql`;
            this.sisense_app.$$http.post(dashboardsUrl, jaql).then((response) => {

                resolve(response.data);

            });
        });
    }

    getSelectorList(selector, grouping, datasource) {


        return new Promise((resolve, reject) => {
            const jaql: any =
            {
                'metadata': [
                    {
                        'dim': selector,
                        'sort': 'asc'
                    }
                ]
            };

            if (grouping) {
                jaql.metadata.push(
                    {
                        'dim': grouping
                    });
            }
            const dashboardsUrl = `/api/datasources/${datasource}/jaql`;
            this.sisense_app.$$http.post(dashboardsUrl, jaql).then((response) => {

                resolve(response.data);

            });
        });
    }

    getPNGForWidget(dashboardId, widgetId) {
        return new Promise((resolve, reject) => {
            this.sisense_app.$$http.post(`/api/dashboards/${dashboardId}/widgets/${widgetId}/export/png`).then((response) => {

                resolve(response.data);

            });
        });

    }

    getCSVForWidget(datasource, jaql, widget) {
        const dashboardsUrl = `/api/datasources/${datasource}/jaql`;
        // this.sisense_app.$$http.post(dashboardsUrl, JSON.stringify(jaql)).then((response) => {
        this.sisense_app.$$http.post(dashboardsUrl, JSON.stringify(jaql)).then((response) => {
            //resolve(response.data)
            const res = response.data;

            // streamSaver.createWriteStream() returns a wirtable byte stream
            // The WritableStream only accepts Uint8Array chunks
            // (no other typed arrays, arrayBuffers or strings are allowed)
            const fileStream = streamSaver.createWriteStream(`${widget.title ? widget.title : 'untitled'}.csv`, {
                size: undefined, // (optional filesize) Will show progress
                writableStrategy: undefined, // (optional)
                readableStrategy: undefined  // (optional)
            });

            const writer = fileStream.getWriter();

            let text = '';
            text += res.headers.join() + '\n';
            //await writer.write(new TextEncoder().encode(res.headers.join() +"\n"))
            if (Array.isArray(res.values[0])) {
                res?.values?.forEach(async (element) => {
                    //writer = fileStream.getWriter()
                    //text += element.join() + "\n"
                    element.forEach((el) => {
                        text += el['text'] + ',';
                    });
                    text = text.slice(0, -1) + '\n';
                    //await writer.write(new TextEncoder().encode(element.join()+"\n"))
                });
            } else {
                res.values.forEach((el) => {
                    text += el['text'] + ',';
                });
                text = text.slice(0, -1) + '\n';
            }


            writer.write(new TextEncoder().encode(text));

            writer.close();

        });
    }


    async deleteDashboard(uid) {
        const orgRef: AngularFirestoreDocument<any> = this.afs.doc(`dashboards/${uid}`);

        const logoDialog = this.dialog.open(ConfirmDashboardDeleteDialog, {
            width: '400px',
            data: { 'uid': uid }
        });

        logoDialog.afterClosed()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                async (res) => {
                    if (res) {
                        await orgRef.delete();

                        this._matSnackBar.open('Dashboard deleted', 'Ok!', {
                            verticalPosition: 'top',
                            duration: 2000
                        });
                        return true;
                    }
                    return false;
                }
            );


    }
    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
