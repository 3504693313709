import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SharedModule } from 'app/shared/shared.module';
import { EditDashboardComponent } from './edit_dashboard.component';

@NgModule({
    declarations: [
        EditDashboardComponent
    ],
    imports     : [
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatTooltipModule,
        SharedModule
    ],
    exports     : [
        EditDashboardComponent
    ]
})
export class EditDashboardModule
{
}
