import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
    selector: 'confirm-dashboard-delete',
    templateUrl: 'confirm-dashboard-delete.dialog.html',
  })
  export class ConfirmDashboardDeleteDialog {

    constructor(
      public dialogRef: MatDialogRef<ConfirmDashboardDeleteDialog>,
      @Inject(MAT_DIALOG_DATA) public data: any) { }

    onNoClick(): void {
      this.dialogRef.close();
    }

    dialogClose(value): void {
        this.dialogRef.close(value);
      }

  }
