import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AnalyticService } from 'app/core/analytic/analytic.service';
import * as Sentry from "@sentry/angular";
@Component({
    selector       : 'reload-dashboard',
    templateUrl    : './reload.component.html',
    encapsulation  : ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.Default,
    exportAs       : 'edit-dashboard'
})
@Sentry.TraceClassDecorator()
export class ReloadDashboardComponent implements OnInit, OnDestroy
{

    /**
     * Constructor
     */
    constructor(
        public _analyticService: AnalyticService,
        public router: Router
    )
    {
    }

    toggleReloadDashboard(){
        // const drawer = this._fuseDrawerService.getComponent('dashboardDrawer');
        // drawer.toggle();
        this._analyticService.reloadDashboard.next(null);
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {

    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
    }

}
