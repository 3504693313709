<!-- <form class="flex flex-col mt-4 overflow-hidden" *ngIf="isAdmin"> -->
<!-- <mat-form-field class="fuse-mat-dense flex-auto">
        <mat-select (selectionChange)='changeOrg($event)' [(value)]="selectedOrg">
            <mat-option *ngFor="let org of availableOrgs" [value]="org.id">{{org.name}}</mat-option>
            <mat-optgroup label="Others">
                <mat-option value="new">New Organization</mat-option>
            </mat-optgroup>
        </mat-select>
    </mat-form-field>
</form> -->
<div class="flex font-sans">
    <ng-select class="flex fuse-mat-dense flex-auto min-w-40" *ngIf="isAdmin" appendTo="body" [items]="availableOrgs" bindLabel="name"
        bindValue="id" [(ngModel)]="selectedOrg" [clearable]="false" [searchable]="true" (change)='changeOrg($event)'>
        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
            <div class="flex gap-4">
                <img [src]="item.logo" class="w-5 h-5">
                <span>{{item.name}}</span>
            </div>

        </ng-template>
    </ng-select>

    <div class="pl-2" *ngIf="isAdmin">
        <button class="next-button" mat-icon-button tabindex="2" [matMenuTriggerFor]="userActions">
            <mat-icon [svgIcon]="'heroicons_solid:plus-circle'"></mat-icon>
        </button>
    </div>
</div>

<mat-menu
    [xPosition]="'before'"
    #userActions="matMenu">
    <button mat-menu-item
    (click)='newDashboard()'>
        <mat-icon [svgIcon]="'heroicons_outline:view-grid-add'"></mat-icon>
            <span>{{'TOOLBAR.ADD_ELEMENT.new_dashboard' | transloco}}</span>
    </button>
    <!-- <mat-divider class="my-2"></mat-divider>
    <button mat-menu-item>
        <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
        <span>Profile</span>
    </button>
    <button mat-menu-item>
        <mat-icon [svgIcon]="'heroicons_outline:cog'"></mat-icon>
        <span>Settings</span>
    </button>
    <button
        mat-menu-item
        [matMenuTriggerFor]="userStatus">
        <mat-icon [svgIcon]="'heroicons_outline:dots-circle-horizontal'"></mat-icon>
        <span>Status</span>
    </button> -->
    <mat-divider class="my-2"></mat-divider>
    <button
        mat-menu-item
        (click)='newOrg()'>
        <mat-icon [svgIcon]="'heroicons_outline:user-group'"></mat-icon>
        <span>{{'TOOLBAR.ADD_ELEMENT.new_organization' | transloco}}</span>
    </button>
</mat-menu>
