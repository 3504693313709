<!-- Navigation -->
<fuse-vertical-navigation
    class="dark bg-gray-900 print:hidden"
    [appearance]="navigationAppearance"
    [mode]="isScreenSmall ? 'over' : 'side'"
    [name]="'mainNavigation'"
    [navigation]="navigation.default"
    [opened]="!isScreenSmall">
    <!-- Navigation header hook -->
    <!-- <ng-container fuseVerticalNavigationContentHeader>
        Logo
        <div class="flex items-center justify-center h-20">
            <img
                class="w-8"
                [src]="selectedOrg?.logo"
                alt="Logo image">
        </div>
    </ng-container> -->

    <ng-container fuseVerticalNavigationContentHeader>
        <!-- Logo -->
        <div class="grid items-center justify-items-center h-30 p-4 mb-2" *ngIf="this.isAdmin">
            <!-- Light version -->
            <img loading=lazy
                *ngIf="selectedOrg?.logo"
                [className]="selectedOrg.logo_white_background ? 'dark:hidden object-contain w-auto rounded-2xl max-h-28 bg-card shadow bg-white p-2': 'dark:hidden h-28 object-contain w-auto'"
                [src]="selectedOrg?.logo"
                alt="Logo image">
            <!-- Dark version -->
            <img loading=lazy
                *ngIf="selectedOrg?.logo"
                [className]="selectedOrg.logo_white_background ? 'object-contain w-auto rounded-2xl bg-card max-h-28 shadow bg-white p-2': 'object-contain h-28 w-auto'"
                [src]="selectedOrg?.logo"
                alt="Logo image">

            <!-- <span class="text-4xl ml-4">{{selectedOrg?.name}}</span> -->
        </div>

        <div class="grid items-center justify-items-center h-30 p-4 mb-2" *ngIf="!this.isAdmin">
            <!-- Light version -->
            <img loading=lazy
                *ngIf="selectedOrg?.logo"
                class="object-contain w-auto"
                src="./assets/images/logo/logo_HP.png"
                alt="Logo image">

            <!-- <span class="text-4xl ml-4">{{selectedOrg?.name}}</span> -->
        </div>
    </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">

    <!-- Header -->
    <div class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
        <div class="flex flex-grow items-center pr-2 space-x-2" style="max-height: 100%">
            <!-- Navigation toggle button -->
            <button
                mat-icon-button
                (click)="toggleNavigation('mainNavigation')">
                <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
            </button>
            <!-- Navigation appearance toggle button -->
            <button
                class="hidden md:inline-flex"
                mat-icon-button
                (click)="toggleNavigationAppearance()">
                <mat-icon [svgIcon]="'heroicons_outline:switch-horizontal'"></mat-icon>
            </button>

            <div class='flex pl-2 space-x-0.5 sm:space-x-2'>
                <date-selector [hidden]="!this.timeFilterEnabled" ></date-selector>
            </div>
            <div class='flex items-center justify-items-end flex-grow pl-2 space-x-0.5 sm:space-x-2' style="height:40px; margin-top: -5px;">
                <community-manager-selector class="w-full" [dashboard]="this.dashboard" [hidden]="!this.selectorFilterEnabled"></community-manager-selector>
            </div>
<!-- 
            <div class='relative mt-1 ml-auto mr-auto'>
                <date-selector [hidden]="!this.timeFilterEnabled" ></date-selector>
            </div>
            <div class='flex ml-auto mr-auto bg-warn'>
                <community-manager-selector [dashboard]="this.dashboard" [hidden]="!this.selectorFilterEnabled"></community-manager-selector>
            </div> -->

            <!-- <date-selector class='relative mt-1 ml-auto mr-auto'></date-selector>
            <community-manager-selector class='relative mt-1 ml-auto mr-auto' *ngIf="this.isCommunityManager"></community-manager-selector> -->
        </div>


        <!-- Components -->
        <div class="flex items-center justify-items-end pl-2 space-x-0.5 sm:space-x-2"> 
            <!-- ml-auto  -->
            <org-select></org-select>
            <edit-dashboard></edit-dashboard>
                        <reload-dashboard></reload-dashboard>
            <download-dashboard></download-dashboard>
            <languages></languages>
            <fuse-fullscreen [tooltip]="'TOOLBAR.FULLSCREEN.toggle' | transloco"></fuse-fullscreen>

            <!-- <search [appearance]="'bar'"></search>
            <shortcuts></shortcuts>
            <messages></messages>
            <notifications></notifications>
            <button
                class="lg:hidden"
                mat-icon-button
                (click)="quickChat.toggle()">
                <mat-icon [svgIcon]="'heroicons_outline:chat-alt-2'"></mat-icon>
            </button> -->
            <user></user>
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>

    <!-- Footer -->
    <!-- <div class="relative flex flex-0 items-center justify-start w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">
        <span class="font-medium text-secondary">Fuse &copy; {{currentYear}}</span>
    </div> -->

</div>

<!-- Quick chat -->
<quick-chat #quickChat="quickChat" hidden></quick-chat>
