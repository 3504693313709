<h1 mat-dialog-title>{{'TOOLBAR.ADD_ELEMENT.new_organization' | transloco}}</h1>
<div mat-dialog-content>
    <form [formGroup]="general_org_form" class="w-100-p">
        <formly-form [form]="general_org_form" [fields]="general_org_fields"
            [model]="general_org_model"></formly-form>
    </form>
</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="undefined">{{'FORMS.cancel' | transloco}}</button>
  <button
  mat-flat-button
  type="button"
  [color]="'primary'" [mat-dialog-close]="general_org_model" [disabled]="this.general_org_form.invalid" cdkFocusInitial>{{'FORMS.save' | transloco}}</button>
</div>
