
import { HttpClient } from '@angular/common/http';
import { Injectable, Injector, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService as Auth0 } from '@auth0/auth0-angular';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { UserService } from 'app/core/user/user.service';
import { environment } from 'environments/environment';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import { Observable, of, ReplaySubject, Subject, throwError } from 'rxjs';
import { catchError, switchMap, take, takeUntil } from 'rxjs/operators';
import { AnalyticService } from '../analytic/analytic.service';
import { Router } from '@angular/router';
import { FuseSplashScreenService } from '@fuse/services/splash-screen';
import { P } from '@angular/cdk/keycodes';



@Injectable()
export class AuthService  implements OnInit {
    private _authenticated: boolean = false;
    private _authUser: ReplaySubject<any> = new ReplaySubject<any>(1);
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _userService: UserService,
        private afAuth: AngularFireAuth,
        private auth0: Auth0,
        private injector: Injector,
        private _matSnackBar: MatSnackBar,
        private _router: Router,
        private splashScreenServcie: FuseSplashScreenService,
    ) {
        this.auth0.isAuthenticated$.subscribe((isAuthenticated) => {
            console.log('ISAUTH', isAuthenticated);
            this._authenticated = isAuthenticated;
            if(isAuthenticated){
                this.auth0.user$.subscribe((user) => {
                    console.log('USER', user);
                    this.authUser = user;
                });
            }
        });

        this.afAuth.authState.subscribe((user) => {
            console.log('AUTHSTATE', user);
        });

        this.afAuth.onAuthStateChanged((user) => {
            console.log('ONAUTHSTATECHANGE', user);
            if(user){
                this.authUser = user;
                if(!this._router.url.includes('analytic') || true){
                    this.splashScreenServcie.hide();
                }
            } else {
                // alert("NO USER, redirect");
                this.signInWithRedirect();
                // this.auth0.loginWithRedirect();
                // debugger;
            }
        });

        // this.afAuth.currentUser.then((user) => {
        //     console.log('CURRENTUSER', user);
        //     this.afAuth.signInWithRedirect(new firebase.default.auth.OAuthProvider('oidc.auth0')).then(
        //         (res) => {
        //             console.log('LOGIN RES', res)
        //             this._matSnackBar.open(`Redirecting`, 'Ok!', {
        //                 verticalPosition: 'top',
        //                 duration: 2000
        //             });
        //         });
        // });
        // this.signOut();

        // // this.signInWithRedirect();
        // this.afAuth.authState
        // .pipe(
        //     take(1),
        //     takeUntil(this._unsubscribeAll)
        // )
        // .subscribe((user) => {
        //     console.log("PORCO", user);
        //     // debugger;
        //     if (user && user.uid) {
        //         this.authUser = user;

        //         // if(!environment.production && !user.email.includes("@hivepower.tech")){
        //         //     document.location.href = 'https://platform.hivepower.tech';
        //         // }

        //         if(!this._router.url.includes('analytic') || true){
        //             this.splashScreenServcie.hide();
        //         }
        //     } else {
        //         console.log('NOUSER', user);
        //         console.log('WHAT CAN WE DO?');
        //         // debugger;
        //         // this.signOut();
        //         // const provider = new firebase.default.auth.OAuthProvider('oidc.auth0');

        //         // alert('NO USER');
        //         // this.afAuth.signInWithRedirect(provider);
        //         // this.afAuth.signInWithPopup(provider).then((result) => {
        //         //     console.log('RESULT', result);
        //         // });
        //         this.signInWithRedirect();
        //     }
        // });
    }

    ngOnInit(): void {
        
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string) {
        localStorage.setItem('accessToken', token);
    }

    get accessToken(): string {
        return localStorage.getItem('accessToken') ?? '';
    }

    /**
     * Setter & getter for access token
     */
    set authUser(value: any) {
        // Store the value
        this._authUser.next(value);
        // IF uid is different, then it's a different user and we need to retrieve the new profile

        this._userService._fUserID$.next(value.uid);
    }

    get authUser$():  Observable<any> {
        return this._authUser.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(email: string): Observable<any> {
        return this._httpClient.post('api/auth/forgot-password', email);
    }

    /**
     * Reset password
     *
     * @param password
     */
    resetPassword(password: string): Observable<any> {
        return this._httpClient.post('api/auth/reset-password', password);
    }

    signInWithRedirect(): void{
        const provider = new firebase.default.auth.OAuthProvider('oidc.auth0');
        this.afAuth.signInWithPopup(provider).then(
            (res) => {
                console.log('LOGIN RES', res)
                this._matSnackBar.open(`Redirecting`, 'Ok!', {
                    verticalPosition: 'top',
                    duration: 2000
                });
            });
    }
    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(credentials: { email: string; password: string }): Observable<any> {
        // Throw error, if the user is already logged in
        if (this._authenticated) {
            return throwError('User is already logged in.');
        }

        return this._httpClient.post('api/auth/sign-in', credentials).pipe(
            switchMap((response: any) => {

                // Store the access token in the local storage
                this.accessToken = response.accessToken;

                // Set the authenticated flag to true
                this._authenticated = true;

                // Store the user on the user service
                this._userService.user = response.user;

                // Return a new observable with the response
                return of(response);
            })
        );
    }

    /**
     * Sign in using the access token
     */
    signInUsingToken(): Observable<any> {
        // Renew token
        return this._httpClient.post('api/auth/refresh-access-token', {
            accessToken: this.accessToken
        }).pipe(
            catchError(() =>

                // Return false
                of(false)
            ),
            switchMap((response: any) => {

                // Store the access token in the local storage
                this.accessToken = response.accessToken;

                // Set the authenticated flag to true
                this._authenticated = true;

                // Store the user on the user service
                this._userService.user = response.user;

                // Return true
                return of(true);
            })
        );
    }

    // /**
    //  * Sign out
    //  */
    // async signOut(): Promise<Observable<any>> {
    //     // Remove the access token from the local storage
    //     localStorage.removeItem('accessToken');
    //     localStorage.removeItem('storedOrg');
    //     // Set the authenticated flag to false
    //     this._authenticated = false;

    //     const sisense = this.injector.get(AnalyticService);

    //     await sisense.initSisense()

    //     // this.auth0.logout({ returnTo: environment.logoutUrl });
    //     // //** TO MOVE  */
    //     // // await this.afAuth.signOut()

    //     // await sisense.sisense_app.$$http.get(environment.sisenseConfig.logout);

    //     return of(true);
    //     return this.afAuth.signOut().then(
    //         async (res) => {
    //             this.auth0.logout({ returnTo: environment.logoutUrl });
    //             await sisense.sisense_app.$$http.get(environment.sisenseConfig.logout);
    //             console.log('LOGOUT RES', res);
    //             this._matSnackBar.open(`Logged out`, 'Ok!', {
    //                 verticalPosition: 'top',
    //                 duration: 2000
    //             });
    //             return of(true);
    //             // this._router.navigate([''], null);
    //         }
    //     )
    // }

    async signOut(): Promise<void> {
        // Remove the access token from the local storage
        localStorage.removeItem('accessToken');
        localStorage.removeItem('storedOrg');
    
        try {
            const sisense = this.injector.get(AnalyticService);
            await sisense.initSisense();
    
            // Logout from Auth0
            this.auth0.logout({ returnTo: environment.logoutUrl });

            // Logout from Firebase
            await this.afAuth.signOut();

            // Logout from Sisense
            sisense.sisense_app.$$http.get(environment.sisenseConfig.logout);

            console.log('LOGOUT RES', 'Successful logout');
            this._matSnackBar.open(`Logged out`, 'Ok!', {
                verticalPosition: 'top',
                duration: 2000
            });

            // Set the authenticated flag to false
            this._authenticated = false;
            
            this._router.navigate([''], null);
        } catch (error) {
            console.error('Logout failed', error);
            this._matSnackBar.open(`Logout failed: ${error.message}`, 'Ok!', {
                verticalPosition: 'top',
                duration: 2000
            });
        }
    
        // Redirect after logout (optional, based on your app's flow)
        // 
    }

    
    /**
     * Sign up
     *
     * @param user
     */
    signUp(user: { name: string; email: string; password: string; company: string }): Observable<any> {
        return this._httpClient.post('api/auth/sign-up', user);
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: { email: string; password: string }): Observable<any> {
        return this._httpClient.post('api/auth/unlock-session', credentials);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean> {
        // Check if the user is logged in
        if (this._authenticated) {
            return of(true);
        }

        // Check the access token availability
        if (!this.accessToken) {
            return of(false);
        }

        // Check the access token expire date
        if (AuthUtils.isTokenExpired(this.accessToken)) {
            return of(false);
        }

        // If the access token exists and it didn't expire, sign in using it
        return this.signInUsingToken();
    }
}
