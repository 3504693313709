import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import localeDeExtra from '@angular/common/locales/extra/de';
import localeFr from '@angular/common/locales/fr';
import localeIt from '@angular/common/locales/it';
import { ErrorHandler, NgModule } from '@angular/core';
// 1. Import the libs you need
import { AngularFireModule } from '@angular/fire';
// import { AngularMyDatePickerModule } from 'angular-mydatepicker';
// import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { AngularFireAnalyticsModule, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireFunctionsModule, REGION } from '@angular/fire/functions';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules,NoPreloading, RouterModule, Router } from '@angular/router';
import { AuthModule } from '@auth0/auth0-angular';
import { FormioAppConfig } from '@formio/angular';
// import { FormioAppConfig } from '@formio/angular';
// import { FormioAppConfig, FormioModule } from '@formio/angular';
import { FuseModule } from '@fuse';
import { FuseMockApiModule } from '@fuse/lib/mock-api';
import { FuseConfigModule } from '@fuse/services/config';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';
import { appConfig } from 'app/core/config/app.config';
import { CoreModule } from 'app/core/core.module';
import { LayoutModule } from 'app/layout/layout.module';
import { mockApiServices } from 'app/mock-api';
import { environment } from 'environments/environment';
import { MarkdownModule } from 'ngx-markdown';
import { AnalyticService } from './core/analytic/analytic.service';
import { AuthService } from './core/auth/auth.service';
import { FormsService } from './core/forms/forms.service';
import { ModulesService } from './core/modules/modules.service';
import { SentryService } from './core/monitoring/sentry.service';
import { OrgService } from './core/org/org.service';
import { UserService } from './core/user/user.service';
import { LazyLoadingLibraryService } from './core/utils/lazy-loading-library.service';
import { UtilsService } from './core/utils/utils.service';

import * as Sentry from "@sentry/angular";
const routerConfig: ExtraOptions = {
    preloadingStrategy       : NoPreloading,
    scrollPositionRestoration: 'enabled'
};

// import { MatFormioModule } from '@formio/angular-material';

registerLocaleData(localeDe, 'de', localeDeExtra);
registerLocaleData(localeEn,'en')
registerLocaleData(localeIt,'it')
registerLocaleData(localeFr,'fr')

@NgModule({
    declarations: [
        AppComponent
    ],
    imports     : [
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes, routerConfig),

        // Fuse, FuseConfig & FuseMockAPI
        FuseModule,
        FuseConfigModule.forRoot(appConfig),
        FuseMockApiModule.forRoot(mockApiServices),

        // Core module of your application
        CoreModule,

        // Layout module of your application
        LayoutModule,

        // 3rd party modules that require global configuration via forRoot
        MarkdownModule.forRoot({}),

        //Firebase
        AngularFireModule.initializeApp(environment.firebaseConfig, 'angular-auth-firebase'),
        AngularFirestoreModule, // firestore
        AngularFireAuthModule, // auth
        AngularFireStorageModule, // storage
        AngularFireFunctionsModule,
        // AngularFireAnalyticsModule,


        // Import the module into the application, with configuration
        AuthModule.forRoot({
            domain: environment.auth0Config.domain,//'auth.hivepower.tech',//'hivepower.eu.auth0.com', //auth.hivepower.tech
            clientId: environment.auth0Config.clientId,//'HnrbAxEYC9gR4FU4Q0oJ34h8uKRAntrc',
            redirectUri: environment.auth0Config.callbackUrl,
            // responseType: 'token id_token',

            // //redirectUri: environment.auth0Config.callbackUrl,
            // //skipRedirectCallback: true,
            // httpInterceptor: {
            //     allowedList: [
            //       {
            //         // Match any request that starts 'https://YOUR_DOMAIN/api/v2/' (note the asterisk)
            //         uri: 'https://auth.hivepower.tech/api/v2/*',

            //         // tokenOptions: {
            //         //   // The attached token should target this audience
            //         //   audience: 'https://auth.hivepower.tech/api/v2/',

            //         //   // The attached token should have these scopes
            //         //   scope: 'read:users'
            //         // }
            //       },
            //       {
            //         uri: 'https://auth.hivepower.tech/api/v2/users',
            //         tokenOptions: {
            //           audience: 'https://auth.hivepower.tech/api/v2/',
            //           scope: 'read:users',
            //         },
            //       }

            //     ],
            //   }
        }),
         ReactiveFormsModule,
         FormlyModule.forRoot({
            extras: { lazyRender: true, checkExpressionOn: 'modelChange'  },
            validationMessages: [
                { name: 'duplicate', message: 'This value is not unique!' },
                { name: 'required', message: 'This field is required' },
              ],
            }),
         FormlyMaterialModule,
        //  AngularMyDatePickerModule,
        FormsModule,
        // HasuraModule
        //  FormioModule
        //  MatFormioModule
        //  NgxDaterangepickerMd.forRoot()
    ],
    bootstrap   : [
        AppComponent
    ],
    providers : [
        AuthService,
        UserService,
        OrgService,
        ModulesService,
        AnalyticService,
        UtilsService,
        SentryService,
        UserTrackingService,
        FormsService,
        LazyLoadingLibraryService,
        {provide: FormioAppConfig, useValue: {}},
        { provide: Sentry.TraceService,
            deps: [Router]},
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        },
        { provide: REGION, useValue: 'europe-west6' },
        ScreenTrackingService,
        UserTrackingService
        // {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
        // {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
    ]
})
export class AppModule
{
}
