/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable, OnDestroy } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Subject } from 'rxjs';

@Injectable()
export class UtilsService implements OnDestroy{

    
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(private afs: AngularFirestore) {  
    }

    async getSmartPricerConfigs(orgId, pricerID): Promise<any>{
        const configs_ref = this.afs.collection('orgs').doc(orgId).collection('modules').doc(pricerID).collection('configs').ref;

        const userConfigQuery = await configs_ref.where('type', '==', 'customer').orderBy('createdDate', 'desc').limit(1).get();
        const masterConfigQuery = await configs_ref.where('type', '==', 'master').orderBy('createdDate', 'desc').limit(1).get();

        let userConfig: any = {};
        let masterConfig: any = {};

        userConfigQuery.forEach((doc) => {
            userConfig =  doc.data();
            userConfig.id = doc.id
          });

        masterConfigQuery.forEach(doc => {
            masterConfig =  doc.data();
            masterConfig.id = doc.id;
          });

        userConfig.schedule = new Date(userConfig.schedule.seconds * 1000).toUTCString()

        return [userConfig, masterConfig];
    }

    async getDefaultFormioSchema(documentId): Promise<any>{
        const schemas = this.afs.collection('formio_schemas').doc(documentId).get().toPromise()

      return schemas
    }
    
    async setDefaultFormioSchema(documentId, values): Promise<any>{
      const smartpricerRef: AngularFirestoreDocument<any> = this.afs.collection('formio_schemas').doc(`manager-${documentId}`);

      let res = await smartpricerRef.set(values, {merge: true});
      return res
      // const schemas = this.afs.collection('formio_schemas').doc(documentId).get();

      // let schema: any = {}
      
      // schemas.forEach((doc) => {
      //     schema =  doc.data();
      //     schema.id = doc.id
      //   });

      // return schema;
  }


    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
