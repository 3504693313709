/* eslint-disable */
import { Component, Inject, OnDestroy } from '@angular/core';
import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslocoService } from '@ngneat/transloco';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';


@Component({
  selector: 'new-org',
  templateUrl: 'new-org.dialog.html',
})
export class NewOrgDialog implements OnDestroy {

  // General information form
  general_org_form = new FormGroup({});
  general_org_model = { id: '', name: '', shared_dashboard: null, datasource: null, modules: { smartpricer: false, communitymanager: false } };
  general_org_fields: FormlyFieldConfig[];
  general_org_loading: boolean = true;

  orgs: any;

  private _unsubscribeAll: Subject<any> = new Subject<any>();
  constructor(
    public dialogRef: MatDialogRef<NewOrgDialog>,
    @Inject(MAT_DIALOG_DATA) public _data: any,
    private _translocoService: TranslocoService) {

    this.orgs = this._data.orgs;



    this._translocoService.selectTranslate('FORMS.name')
      .pipe(takeUntil(this._unsubscribeAll), take(1))
      .subscribe(
        (translation) => {
          this.general_org_fields = [
            {
              key: 'id',
              type: 'input',
              templateOptions: {
                label: 'ID',
                type: 'string',
                required: true,
                appearance: 'outline'
              },
              validators: {
                validation: [isIDUnique(this.orgs, this.general_org_model)],
              },
            },
            {
              key: 'name',
              type: 'input',
              templateOptions: {
                label: translation,
                type: 'string',
                required: true,
                appearance: 'outline'
              },
            },
          ];
        })

    this.general_org_fields = [... this.general_org_fields];
    this.general_org_loading = false;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  /**
* On destroy
*/
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

}


export function isIDUnique(orgs: any[], editOrg: any): ValidatorFn {

  return (control: AbstractControl): ValidationErrors | null => {

    const id = control.value;//: string

    let duplicate = false;
    if (editOrg) {
      orgs.forEach((el) => {
        if ((editOrg && el.id == id && el.id != editOrg.id) || (!editOrg && el.id == id)) {
          duplicate = true;
        }
      });
    }

    if (duplicate) {
      return { duplicate: true };
    }

    return null;

  };
}
