import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AnalyticService } from 'app/core/analytic/analytic.service';
import { LazyLoadingLibraryService } from 'app/core/utils/lazy-loading-library.service';
import { saveAs } from 'file-saver';
import * as htmlToImage from 'html-to-image';
// import html2canvas from 'html2canvas';
// import jsPDF from 'jspdf';

declare const jsPDF: any



@Component({
    selector       : 'download-dashboard',
    templateUrl    : './download_dashboard.component.html',
    encapsulation  : ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.Default,
    exportAs       : 'download-dashboard'
})
export class DownloadDashboardComponent
{

    /**
     * Constructor
     */
    constructor(
        public _analyticService: AnalyticService,
        public router: Router,
        private lazyLoadService: LazyLoadingLibraryService
    )
    {
    }

    downloadDashboard(){
            
        var node = document.getElementById('dashboard');

        // html2canvas(document.getElementById('dashboard')).then(canvas => {

        //     debugger;
     
        //    /// document.body.appendChild(canvas);
        //    canvas.toDataURL();
        //    saveAs(canvas.toDataURL(), "blallo.png");
        //  });


            htmlToImage.toPng(node)
            .then(function (dataUrl) {
                // var img = new Image();
                // img.src = dataUrl;
                // document.body.appendChild(img);
                if(false){
                    // saveAs(dataUrl, widget.title ? widget.title : widget.id + ".png");
                } else {
                    var img = new Image();
                    img.src = dataUrl;
                    // document.body.appendChild(img);
                    img.onload =
                        ()=>{
                            let fileWidth = 208;
                            let fileHeight = img.height * fileWidth / img.width;

                            var jsPDF: any

                            this.lazyLoadService.loadJs('https://cdnjs.cloudflare.com/ajax/libs/jspdf/2.3.1/jspdf.umd.min.js').subscribe(() => {

                                // const FILEURI = canvas.toDataURL('image/png')
                                let PDF = new jsPDF('p', 'mm', 'a4');
                                let position = 0;
                                PDF.addImage(dataUrl, 'PNG', 0, position, fileWidth, fileHeight)
                                
                                PDF.save('test.pdf');
                            });


                        }
                    

                }
                
                // console.log('image', img)
            })
            .catch(function (error) {
                console.error('oops, something went wrong!', error);
            });

        // console.log('DATA', DATA)
    }
}
