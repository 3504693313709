import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { SharedModule } from 'app/shared/shared.module';
import { OrgSelectComponent } from './org-select.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatDividerModule } from '@angular/material/divider';
import { MatDialogModule } from '@angular/material/dialog';
import { NewOrgDialog } from './new_org/new-org.dialog';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { FormlyModule } from '@ngx-formly/core';
import { NewDashboardDialog } from './new_dashboard/new-dashboard.dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { TranslocoModule } from '@ngneat/transloco';
import { FormioModule } from '@formio/angular';
@NgModule({
    declarations: [
        OrgSelectComponent,
        NewOrgDialog,
        NewDashboardDialog
    ],
    imports     : [
        NgSelectModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        FormsModule,
        MatFormFieldModule,
        MatSelectModule,
        SharedModule,
        MatDialogModule,
        MatDividerModule,
        MatSnackBarModule,
        FormioModule,
        FormlyModule,
        FormlyMaterialModule,
        TranslocoModule

    ],
    exports     : [
        OrgSelectComponent
    ]
})
export class OrgSelectModule
{
}
